import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  input: {
    width: '100%',
  },
}));

export default function CowCardBhbInput(props) {
  const { index, value, updateCowProperty, invalid } = props;
  const classes = useStyles();
  const { t } = useTranslation('formComponent');

  return (
    <TextField
      variant='outlined'
      label={t('BHB')}
      className={classes.input}
      error={invalid}
      helperText={invalid ? t('invalidInput') : ''}
      type='number'
      id={`cowNumber-${index}-bhb`}
      data-idx={index}
      name='bhbValue'
      value={value}
      onChange={(e) => {
        updateCowProperty(index, e.target.name, e.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
      size='small'
    />
  );
}

CowCardBhbInput.propTypes = {
  index: PropTypes.number.isRequired,
  value: PropTypes.string,
  updateCowProperty: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
};
