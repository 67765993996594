import React, { useContext, useEffect, useState, useCallback } from 'react';

import { HeaderContext } from '../../Contexts/HeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, makeStyles, Typography } from '@material-ui/core';
import SettingsInformation from './SettingsInformation';
import SettingsRadioButtons from './SettingsRadioButtons';
import SettingsChangePassword from './SettingsChangePassword';
import {
  getPreparationOptions,
  getSampleMethodOptions,
} from '../../Services/Utility/MeasurementInformation';
import DataService from '../../Services/DataService';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
    height: '100%',
  },
  wrapperDesktop: {
    width: '100%',
  },
  wrapperMobile: {},
  container: {
    width: '50%',
    padding: theme.spacing(1),
    '&>div:last-of-type': {
      paddingBottom: '5rem',
    },
  },
  mobileContainer: {
    width: '100%',
  },
  settingGroup: {
    marginBottom: theme.spacing(1.5),
    '&>div+div': {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function Settings() {
  const { t } = useTranslation('settings');
  const classes = useStyles();
  const { setHeaderTitle } = useContext(HeaderContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { canRegister } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [currentPreparationSettings, setCurrentPreparationSettings] = useState(null);
  const [currentMethodSettings, setCurrentMethodSettings] = useState(null);

  const preparationOptions = getPreparationOptions();
  const methodOptions = getSampleMethodOptions();

  const fetchCurrentSettings = useCallback(async () => {
    const settings = await DataService.fetchData('KetoskollSiteSettings/GetSiteSettings');

    if (settings instanceof Error) {
      setSnackbar({ text: 'fetchSiteSettingsError', severity: 'warning', open: true });
    } else {
      setCurrentPreparationSettings(settings.energyType.toString());
      setCurrentMethodSettings(settings.measurementPackage.toString());
    }
  }, [setSnackbar]);

  useEffect(() => {
    setHeaderTitle(t('settings'));
    document.title = `${t('settings')} | KetosKoll`;
  }, [setHeaderTitle, t]);

  useEffect(() => {
    if (localStorage.getItem('siteid') !== 'null' && localStorage.getItem('siteid')) {
      fetchCurrentSettings();
    }
  }, [fetchCurrentSettings]);

  return (
    <div className={isMobile ? classes.wrapper : `${classes.wrapper} ${classes.wrapperDesktop}`}>
      <div
        className={isMobile ? `${classes.container} ${classes.mobileContainer}` : classes.container}
      >
        {canRegister && (
          <>
            <SettingsInformation />
            <div className={classes.settingGroup}>
              <Typography variant='h4' gutterBottom>
                {t('farmSettings')}
              </Typography>
              <SettingsRadioButtons
                settingsType='preparation'
                options={preparationOptions}
                currentSettings={currentPreparationSettings}
                reloadSettings={fetchCurrentSettings}
              />
              <SettingsRadioButtons
                settingsType='method'
                options={methodOptions}
                currentSettings={currentMethodSettings}
                reloadSettings={fetchCurrentSettings}
              />
            </div>
          </>
        )}
        <div className={classes.settingGroup}>
          <Typography variant='h4' gutterBottom>
            {t('myProfile')}
          </Typography>
          <SettingsChangePassword />
        </div>
      </div>
    </div>
  );
}
