import React from 'react';
import { Typography, Card, makeStyles } from '@material-ui/core';
import CalfIcon from '../../assets/calf.svg';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getDIM } from '../../Services/Utility/CowUtils';

const useStyles = makeStyles((theme) => ({
  yellowCard: {
    boxShadow: theme.shadows.small,
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    borderRadius: '4px',
    backgroundColor: '#FCF4E1',
  },
  card: {
    boxShadow: theme.shadows.small,
    padding: theme.spacing(1, 1, 0),
    marginTop: theme.spacing(0.5),
    borderRadius: '4px',
  },
  iconAndHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
  },
  circle: {
    background: theme.palette.secondary.main,
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px',
  },
  cowList: {
    padding: 0,
    margin: 0,
  },
  listItem: {
    padding: '16px 0',
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  DIM: {
    color: theme.palette.grays.gray60,
  },
}));

export default function SecondTestCows({ cows, pageDate }) {
  const { t } = useTranslation('to-do');
  const classes = useStyles();

  return (
    <>
      <Typography variant='h4'>{t('secondTest')}</Typography>
      <Card className={classes.yellowCard} elevation={0}>
        <Typography>{t('secondTestExplained')}</Typography>
      </Card>
      <Card className={classes.card} elevation={0}>
        <div className={classes.iconAndHeader}>
          <div className={classes.circle}>
            <img src={CalfIcon} alt='' />
          </div>
          <Typography variant='h5'>{t('secondTestAction')}</Typography>
        </div>
        <ul className={classes.cowList}>
          {cows.map((cow) => {
            return (
              <li key={cow.number} className={classes.listItem}>
                <Typography variant='h5'>{cow.number}</Typography>
                <Typography variant='body2' className={classes.DIM}>
                  {`${t('DIM')} ${getDIM(new Date(cow.lastCalvingDate), pageDate)}`}
                </Typography>
              </li>
            );
          })}
        </ul>
      </Card>
    </>
  );
}

SecondTestCows.propTypes = {
  cows: PropTypes.array.isRequired,
  pageDate: PropTypes.instanceOf(Date),
};
