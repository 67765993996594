import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default function ResultInfoContent() {
  const classes = useStyles();
  const { t } = useTranslation('resultInfoBox');

  return (
    <List className={classes.container}>
      <ListItem>
        <ListItemText primary={t('text')} />
      </ListItem>
    </List>
  );
}
