import React, { useState, useContext, useCallback } from 'react';
import { makeStyles, Paper, Typography, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PropTypes from 'prop-types';
import { getIconType } from '../../../../Services/Utility/MeasurementInformation';
import { useTranslation, Trans } from 'react-i18next';
import { enums } from '../../../../Services/Enumerations';
import DataService from '../../../../Services/DataService';
import { SnackbarContext } from '../../../../Contexts/SnackbarContext';
import { isFuture } from 'date-fns';
import CancelActionDialog from './CancelActionDialog';

const useStyles = makeStyles((theme) => ({
  actionCard: {
    boxShadow: theme.shadows.small,
    padding: theme.spacing(1, 1, 0),
    marginTop: theme.spacing(0.5),
    borderRadius: '4px',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    '&>div:first-child': {
      display: 'flex',
      justifyContent: '',
      alignItems: 'center',
    },
  },
  actionIcon: {
    width: '35px',
    height: '35px',
    marginRight: theme.spacing(1),
  },
  listRow: {
    padding: theme.spacing(0.5, 0),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&>div': {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0, 0.5, 1),
      marginLeft: theme.spacing(-1),
    },
  },
  orange: {
    backgroundColor: theme.palette.complementColors.lightRed,
  },
  lightOrange: {
    backgroundColor: `${theme.palette.complementColors.lightRed50} !important`,
  },
  checkBox: {
    padding: 0,
    marginRight: theme.spacing(1),
    color: theme.palette.grays.gray100,
  },
  explanation: {
    backgroundColor: theme.palette.grays.gray5,
    padding: theme.spacing(1),
    margin: theme.spacing(0, -1),
  },
  day: {
    cursor: 'pointer',
    color: theme.palette.grays.gray50,
  },
  iconButton: {
    margin: '-12px',
    color: 'black',
  },
  linkToInstruction: {
    color: 'inherit',
    display: 'block',
    marginTop: theme.spacing(1.5),
  },
  checkBoxAndCowNumber: {
    cursor: 'pointer',
  },
  tooltipWrapper: {
    // Tooltip arrow positioning (override MUI css)
    left: '-0.5rem !important',
    top: '4px !important',
  },
  tooltip: {
    maxWidth: '245px',
    padding: '8px 12px',
  },
  summary: {
    marginTop: theme.spacing(1.5),
  },
  bulletList: {
    margin: '0 0 0 1.5rem',
    padding: '0',

    '&>li': {
      paddingLeft: '0.5rem',
    },
  },
  moreButton: {
    color: 'black',
    marginLeft: '8px',
  },
}));

export default function Action(props) {
  const { type, openDialog, action, cows, activePage, refreshTodos } = props;
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();
  const { t } = useTranslation('to-do');
  let cowCount = 0;

  const isFutureTask = useCallback(() => {
    return isFuture(activePage);
  }, [activePage]);

  const [explanationBoxIsOpen, setExplanationBoxIsOpen] = useState(false);
  const [checkedState, setCheckedState] = useState(
    cows.flatMap((cow) => cow.actionPlans.map((ap) => ap.activeAction)),
  );
  const [cancelActionDialogOpen, setCancelActionDialogOpen] = useState(false);
  const [selectedCow, setSelectedCow] = useState();

  const getActionIcon = () => {
    const icon = getIconType(action.text);

    return icon ? <img src={icon} alt='' className={classes.actionIcon} /> : <></>;
  };
  async function toggleCheckBoxStatus(actionPlanId, status) {
    const response = await DataService.putObject(
      'Action/PutActiveAction',
      { activeAction: status },
      actionPlanId,
    );
    if (response instanceof Error) {
      setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
    }
  }

  function handleClickCheckbox(actionPlanId, currentStatus) {
    toggleCheckBoxStatus(actionPlanId, !currentStatus);
  }

  function handleOnChange(position) {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item,
    );

    setCheckedState(updatedCheckedState);
  }

  function handleClickMoreOptions(cow) {
    setSelectedCow(cow);
    setCancelActionDialogOpen(true);
  }

  const ExplanationBox = () => {
    return (
      <div
        className={
          type === enums.actionType.counselings
            ? `${classes.explanation} ${classes.lightOrange}`
            : classes.explanation
        }
      >
        <Typography component={'div'}>
          <Trans
            ns='to-do'
            i18nKey={action.text}
            count={cowCount}
            components={{
              ul: <ul className={classes.bulletList} />,
              li: <li />,
              summary: <div className={classes.summary} />,
            }}
          />
        </Typography>
      </div>
    );
  };

  return (
    <Paper
      elevation={0}
      className={
        type === enums.actionType.counselings
          ? `${classes.actionCard} ${classes.orange}`
          : classes.actionCard
      }
    >
      {selectedCow && (
        <CancelActionDialog
          isOpen={cancelActionDialogOpen}
          closeDialog={() => setCancelActionDialogOpen(false)}
          cow={selectedCow}
          refreshTodos={refreshTodos}
        />
      )}
      <div
        className={classes.cardHeader}
        onClick={() => setExplanationBoxIsOpen(!explanationBoxIsOpen)}
      >
        <div>
          {getActionIcon()}
          <Typography variant='h5'>{t(`${action.text}Header`)}</Typography>
        </div>
        <IconButton
          onClick={() => setExplanationBoxIsOpen(!explanationBoxIsOpen)}
          classes={{ root: classes.iconButton }}
        >
          {explanationBoxIsOpen ? <CloseIcon /> : <InfoOutlinedIcon />}
        </IconButton>
      </div>
      {explanationBoxIsOpen && <ExplanationBox />}
      {cows.map((cow) => {
        return cow.actionPlans.map((ap) => {
          cowCount++;
          const checkboxIndex = cowCount - 1;
          return (
            <div className={classes.listRow} key={ap.actionPlanId}>
              <div
                onClick={() => {
                  if (!isFutureTask() && type !== enums.actionType.counselings) {
                    handleOnChange(checkboxIndex);
                    handleClickCheckbox(ap.actionPlanId, checkedState[checkboxIndex]);
                  }
                }}
                className={
                  isFutureTask() || type === enums.actionType.counselings
                    ? ''
                    : classes.checkBoxAndCowNumber
                }
              >
                {type !== enums.actionType.counselings && (
                  <Tooltip
                    title={<Typography variant='subtitle2'>{t('tomorrowTaskTooltip')}</Typography>}
                    placement='top-start'
                    enterTouchDelay={0}
                    disableFocusListener={!isFutureTask()}
                    disableHoverListener={!isFutureTask()}
                    disableTouchListener={!isFutureTask()}
                    arrow
                    classes={{ popper: classes.tooltipWrapper, tooltip: classes.tooltip }}
                  >
                    <span>
                      <Checkbox
                        id={`custom-checkbox-${checkboxIndex}`}
                        classes={{ root: classes.checkBox }}
                        color='primary'
                        checked={!checkedState[checkboxIndex]}
                        disabled={isFutureTask()}
                      />
                    </span>
                  </Tooltip>
                )}
                <Typography variant='h5'>{cow.subject.name}</Typography>
              </div>
              <div>
                <Typography
                  variant='body2'
                  className={classes.day}
                  onClick={() => openDialog(true)}
                >{`${t('day')} ${ap.actionDay}`}</Typography>
                <IconButton
                  onClick={() => handleClickMoreOptions(cow)}
                  classes={{ root: classes.moreButton }}
                >
                  <MoreHorizIcon />
                </IconButton>
              </div>
            </div>
          );
        });
      })}
    </Paper>
  );
}

Action.propTypes = {
  type: PropTypes.string,
  openDialog: PropTypes.func.isRequired,
  action: PropTypes.object.isRequired,
  cows: PropTypes.array.isRequired,
  activePage: PropTypes.instanceOf(Date).isRequired,
  refreshTodos: PropTypes.func.isRequired,
};
