import React, { useState, useEffect, useContext, useCallback } from 'react';
import DataService from '../../Services/DataService';
import MeasurementCard from '../MeasurementCard';
import DeleteMeasurementDialog from './DeleteMeasurementDialog';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import {
  useMediaQuery,
  Input,
  Button,
  Typography,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  Paper,
  CircularProgress,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import EmptyState from './EmptyState';
import CowNotFound from './CowNotFound';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    margin: '0 5vw 0 5vw',
  },
  desktopContainer: {
    margin: '0 20vw 0 20vw',
  },
  list: {
    overflow: 'auto',
    height: '80vh',
    margin: theme.spacing(0, -1),
    padding: theme.spacing(0, 1),
  },
  siteSelectorCard: {
    marginTop: '1rem',
    padding: '1rem',
    boxShadow: theme.shadows.small,
    borderRadius: '4px',
    '& p': {
      marginBottom: theme.spacing(1),
    },
  },
  siteSelector: {
    width: '100%',
  },
  siteHeader: {
    marginBottom: '1rem',
  },
  searchBar: {
    marginTop: '1rem',
    marginBottom: '1rem',
    border: '1px solid #009D59',
    borderRadius: '4px',
    fontSize: '16px',
    display: 'flex',
    background: 'white',
    height: theme.spacing(2.5),
    width: '100%',
    marginRight: '0.5rem',
    padding: '0.563rem 0.313rem 0.563rem 0.5rem',
  },
  searchBarText: {
    margin: '0 0 0 1rem',
  },
  cardWrapper: {
    marginBottom: '1rem',
    width: '100%',
  },
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  searchButton: {
    minWidth: '3.75rem',
    maxWidth: '3.75rem',
  },
  searchBarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function Measurements() {
  const { setHeaderTitle } = useContext(HeaderContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { sites, selectedSite, setSelectedSite, userSite } = useContext(UserContext);
  const [measurements, setMeasurements] = useState([]);

  const [searchString, setSearchString] = useState('');
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 50;
  const siteId = userSite !== null ? userSite : selectedSite !== null ? selectedSite.siteid : null;
  const [cow, setCow] = useState('');
  const [queryParams, setQueryParams] = useState({
    pageSize: pageSize,
    pageNumber: pageNumber,
    identifier: cow,
  });
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:640px)');
  const { t } = useTranslation(['measurements', 'actionPackage']);
  const [sortedSites, setSortedSites] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [selectedMeasurement, setSelectedMeasurement] = useState(null);
  const [cowNotFound, setCowNotFound] = useState(false);

  const handleOpenDeleteDialog = useCallback((measurementId) => {
    setSelectedMeasurement(measurementId);
    setShowDeleteDialog(true);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setShowDeleteDialog(false);
  }, []);

  const onDeleteMeasurement = useCallback(
    (measurementId) => {
      const indexOfMeasurementToDelete = measurements.findIndex(
        (measurement) => measurement.measurement.measurementId === measurementId,
      );
      const measurementsCopy = [].concat(measurements);
      measurementsCopy.splice(indexOfMeasurementToDelete, 1);

      setMeasurements(measurementsCopy);
    },
    [measurements],
  );

  const fetchMeasurements = useCallback(async () => {
    const values = await DataService.fetchDataById(
      'Measurement/GetBySiteId',
      siteId,
      queryParams,
    ).then((response) => {
      if (response === undefined) {
        // did not get a response, server probably down
        setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
        return null;
      }
      return response;
    });

    if (values instanceof Error) {
      if (values.response.status === 404) {
        setCowNotFound(true);
      } else {
        setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
      }
    } else if (values !== null) {
      setCowNotFound(false);
      testHasMore(values);
      setMeasurements((prevMeasurements) => {
        return queryParams.pageNumber === 1 ? values : [...prevMeasurements, ...values];
      });
    }
    setLoading(false);
  }, [queryParams, setSnackbar, siteId]);

  useEffect(() => {
    setHeaderTitle(t('measurementsTitle'));
    setSortedSites(sites.sort(compareSites));
    document.title = `${t('measurementsTitle')} | KetosKoll`;
  }, [setHeaderTitle, t, sites]);

  useEffect(() => {
    setQueryParams({
      pageSize: pageSize,
      pageNumber: pageNumber,
      identifier: cow,
    });
  }, [cow, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
    setHasMore(true);
  }, [siteId, cow]);

  useEffect(() => {
    setLoading(true);

    try {
      if (siteId !== null) {
        fetchMeasurements();
      }
    } catch (e) {
      console.log(e);
    }
  }, [siteId, queryParams, fetchMeasurements]);

  const compareSites = (first, second) => {
    const site1 = first.name.toLowerCase();
    const site2 = second.name.toLowerCase();
    if (site1 < site2) return -1;
    if (site1 > site2) return 1;
    return 0;
  };

  const testHasMore = (newValues) => {
    if (newValues.length < pageSize) {
      setHasMore(false);
    }
  };

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
    if (bottom && hasMore && !loading) {
      setPageNumber(pageNumber + 1);
    }
  };

  function onInputChange(e) {
    setSearchString(e.target.value);

    // User has emptied search field
    if (e.target.value === '') {
      setCow('');
    }
  }

  function onSearch() {
    if (searchString) {
      setCow(searchString);
    }
  }

  function onSiteSelected(e) {
    setSelectedSite(sites.find((site) => site.siteid === e.target.value));
  }

  return (
    <div
      className={isMobile ? classes.container : `${classes.container} ${classes.desktopContainer}`}
    >
      {sites.length > 0 && selectedSite !== null ? (
        <Paper elevation={0} className={classes.siteSelectorCard}>
          <Typography variant='body2' className={classes.siteHeader}>
            {t('chooseFarm')}
          </Typography>
          <FormControl className={classes.siteSelector}>
            <Select value={selectedSite.siteid} onChange={onSiteSelected}>
              {sortedSites.map((site) => (
                <MenuItem key={site.siteid} value={site.siteid}>
                  {site.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Paper>
      ) : (
        <></>
      )}

      <div className={classes.searchBarWrapper}>
        <Input
          className={classes.searchBar}
          classes={{ input: classes.searchBarText }}
          color='primary'
          disableUnderline
          type='search'
          variant='outlined'
          placeholder={t('searchPlaceholder')}
          onChange={onInputChange}
          value={searchString}
          disabled={loading}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSearch();
              e.preventDefault();
            }
          }}
        />
        <Button
          variant='contained'
          color='primary'
          disableElevation
          className={classes.searchButton}
          onClick={onSearch}
          disabled={!searchString || loading}
        >
          <SearchIcon />
        </Button>
      </div>

      {cowNotFound ? (
        <CowNotFound />
      ) : (
        <div className={classes.list} onScroll={handleScroll}>
          {measurements.length > 0 ? (
            <>
              {measurements.map((measurement) => {
                return (
                  <div key={measurement.measurement.measurementId} className={classes.cardWrapper}>
                    <MeasurementCard
                      measurement={measurement.measurement}
                      actionPackage={measurement.usedActionPackage}
                      openDeleteDialog={handleOpenDeleteDialog}
                    />
                  </div>
                );
              })}
              {loading && (
                <div className={classes.circularProgress}>
                  <CircularProgress />
                </div>
              )}
            </>
          ) : (
            <EmptyState />
          )}
        </div>
      )}

      {showDeleteDialog && (
        <DeleteMeasurementDialog
          closeDialog={handleCloseDeleteDialog}
          measurement={selectedMeasurement}
          onDeleteMeasurement={onDeleteMeasurement}
        />
      )}
    </div>
  );
}
