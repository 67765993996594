import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
} from '@material-ui/core';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { yyyymmdd } from '../../Services/Utility/DateUtils';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '4px',
    boxShadow: theme.shadows.small,
    height: 'auto',
    overflow: 'auto',
    padding: theme.spacing(1, 1, 0, 1),
  },
  list: {
    margin: '0 -1rem',
    '&>li+li': {
      borderTop: `1px solid ${theme.palette.grays.gray20}`,
    },
  },
  listIcon: {
    minWidth: '1.25rem',
    marginRight: '0.5rem',
  },
}));

export default function Confirmation(props) {
  const { testType, reasonType, cowState, testDate } = props;
  const classes = useStyles();
  const { t } = useTranslation('formComponent');

  return (
    <Paper className={classes.card} elevation={0}>
      <Typography align='center' variant='h4' gutterBottom>
        {t('confirmationTitle')}
      </Typography>
      <Typography>{`${t('summaryDate', { count: cowState.length })}: ${yyyymmdd(
        testDate,
      )}`}</Typography>
      <Typography>
        {`${t('test')}: ${testType === 1 ? t('milk') : t('blood')} - 
        ${reasonType === 1 ? t('symptom') : t('strategic')}`}
      </Typography>

      <List classes={{ root: classes.list }}>
        {cowState.map((val, idx) => {
          return (
            <ListItem key={idx}>
              <ListItemIcon className={classes.listIcon}>
                <LocalOfferOutlinedIcon className={classes.tagIcon} />
              </ListItemIcon>
              <ListItemText classes={{ primary: classes.listText }} primary={val.identifier} />
              <ListItemText
                classes={{ primary: classes.listText }}
                primary={`${t('DIM')}: ${val.daysInMilk}`}
              />
              <ListItemText
                classes={{ primary: classes.listText }}
                primary={`BHB: ${val.bhbValue}`}
              />
            </ListItem>
          );
        })}
      </List>
    </Paper>
  );
}

Confirmation.propTypes = {
  testType: PropTypes.number.isRequired,
  reasonType: PropTypes.number.isRequired,
  cowState: PropTypes.array.isRequired,
  testDate: PropTypes.instanceOf(Date),
};
