import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Card,
  List,
  ListItem,
  ListItemText,
  Fade,
  FormControl,
  FormHelperText,
  Select,
  Input,
  Typography,
  MenuItem,
  Divider,
  useMediaQuery,
  ListItemIcon,
  makeStyles,
} from '@material-ui/core';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { MenuContext } from '../../Contexts/MenuContext';
import { UserContext } from '../../Contexts/UserContext';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import HelpIcon from '@material-ui/icons/Help';
import CopyrightIcon from '@material-ui/icons/Copyright';
import footprint from '../../assets/klövar.svg';
import ExternalLink from '../ExternalLink';
import PortalIcon from '../../assets/portal_icon.svg';
import PieChartIcon from '@material-ui/icons/PieChart';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StatisticsDialog from './StatisticsDialog';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  listItem: {
    float: 'left',
    textAlign: 'left',
  },
  footerIcon: {
    alignSelf: 'flex-start',
    width: '50px',
    height: '50px',
    margin: '10px 10.4px 10px 20px',
  },
  list: {
    paddingTop: '0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  selectedItem: {
    backgroundColor: 'lightgray',
  },
  linkStyleGreen: {
    color: 'black',
    '&:hover': {
      color: '#4caf50 !important',
    },
  },
  smallInfoBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '80px',
  },
  copyrightIcon: {
    fontSize: '1rem',
    verticalAlign: 'sub',
  },
  caption: {
    padding: '1rem',
  },
  roundedImage: {
    backgroundColor: theme.palette.complementColors.blueGrey,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    marginRight: '24px',
  },
  pieChartIcon: {
    marginRight: '30px',
    color: '#757575',
  },
  helpIcon: {
    marginRight: '30px',
    color: '#757575',
  },
  dialogLink: {
    textDecoration: 'none',
    color: 'inherit',
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: '8px',
    height: '12px',
    width: '12px',
  },
}));

export default function Menu(props) {
  const onSignOut = props.onSignOut;
  const { t, i18n } = useTranslation(['menu', 'graph']);
  const classes = useStyles();
  const now = new Date();
  const isMobile = useMediaQuery('(max-width:1160px)');

  const { canRegister, userName } = useContext(UserContext);
  const [language, setLanguage] = useState(i18n.language ? i18n.language.slice(0, 2) : 'en');
  const [mobileStatisticsDialogOpen, setMobileStatisticsDialogOpen] = useState(false);

  const changeLanguage = (lng) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  };

  return (
    <Fade in={true}>
      <Card className={classes.wrapper}>
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <ListItemText primary={userName} />
          </ListItem>
          {canRegister && <ListItemLink to='/' primary={t('to-do')} />}
          {canRegister && <ListItemLink to='/new-test' primary={t('newMeasurement')} />}
          <ListItemLink to='/measurements' primary={t('measurements')} />
          <ListItemLink to='/settings' primary={t('settings')} />
          <Divider component='li' />
          <ExternalLink
            url={process.env.REACT_APP_PORTAL_URL}
            icon={
              <div className={classes.roundedImage}>
                <img src={PortalIcon} alt={'Portal Icon'} />
              </div>
            }
            text={t('portal')}
          />
          {isMobile && (
            <div className={classes.dialogLink} onClick={() => setMobileStatisticsDialogOpen(true)}>
              {<PieChartIcon alt='Statistics icon' className={classes.pieChartIcon} />}
              <Typography>{t('statistics')}</Typography>
              <InfoOutlinedIcon className={classes.icon} />
            </div>
          )}
          {isMobile && mobileStatisticsDialogOpen && (
            <StatisticsDialog closeDialog={() => setMobileStatisticsDialogOpen(false)} />
          )}
          {!isMobile && (
            <ExternalLink
              url={process.env.REACT_APP_PORTAL_URL + '/statistics/ketoskoll/ketosis-over-time'}
              icon={<PieChartIcon className={classes.pieChartIcon} alt={'Statistic icon'} />}
              text={t('statistics')}
            />
          )}
          <Divider component='li' />
          <ListItemLink to='/login' primary={t('signOut')} onClick={() => onSignOut()} />
          {isMobile && (
            <ListItem className={classes.listItem}>
              <FormControl fullWidth>
                <FormHelperText className={classes.listItem}>{t('Change Language')}</FormHelperText>
                <Select
                  value={language}
                  onChange={(e) => changeLanguage(e.target.value)}
                  name='change language'
                  input={<Input name='name' />}
                  IconComponent={KeyboardArrowDown}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                    transformOrigin: {
                      vertical: 'center',
                      horizontal: 'right',
                    },
                  }}
                >
                  <MenuItem value='sv'>
                    <Typography variant='caption' align='center'>
                      Svenska
                    </Typography>
                  </MenuItem>
                  <MenuItem value='en'>
                    <Typography variant='caption' align='center'>
                      English
                    </Typography>
                  </MenuItem>
                  <MenuItem value='fr'>
                    <Typography variant='caption' align='center'>
                      Français
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </ListItem>
          )}
          <ExternalLink
            url={'https://knowledge.agricam.se/knowledge/ketoskoll'}
            icon={
              <div className={classes.helpIcon}>
                <HelpIcon alt={'FAQ Icon'} />
              </div>
            }
            text={t('Faq')}
          />
          <ListItem
            component='a'
            key='Email'
            href='https://knowledge.agricam.se/knowledge/kb-tickets/new'
            target='_blank'
            rel='noreferrer'
            classes={{ root: classes.linkStyleGreen }}
          >
            <ListItemIcon>
              <MailIcon />
            </ListItemIcon>
            <ListItemText>{t('contactSupport')}</ListItemText>
          </ListItem>
          <ListItem
            component='a'
            key='Phone'
            href='tel:+46768619908'
            classes={{ root: classes.linkStyleGreen }}
          >
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText>+46768619908</ListItemText>
          </ListItem>
          <div className={classes.smallInfoBox}>
            <Typography variant='caption' className={classes.caption}>
              Version: {process.env.REACT_APP_VERSION}
            </Typography>
            <Typography variant='caption' className={classes.caption}>
              <CopyrightIcon className={classes.copyrightIcon} />{' '}
              {`${now.getFullYear()} Agricam AB`}
            </Typography>
          </div>
        </List>
        <div className={classes.footerIcon}>
          <img src={footprint} alt='Icon' />
        </div>
      </Card>
    </Fade>
  );
}

function ListItemLink(props) {
  const { primary, to, onClick, children } = props;
  const { setShowQuestionmark } = useContext(HeaderContext);
  const { setMenuActive } = useContext(MenuContext);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => <NavLink exact to={to} innerRef={ref} {...itemProps} />),
    [to],
  );

  function onMenuItemPressed() {
    setShowQuestionmark(false);
    if (isMobile) {
      setMenuActive(false);
    }
  }

  return (
    <ListItem
      button
      component={renderLink}
      activeClassName={classes.selectedItem}
      className={classes.listItem}
      onClick={onClick || onMenuItemPressed}
    >
      <ListItemText primary={primary} />
      {children}
    </ListItem>
  );
}

ListItemLink.propTypes = {
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
};

Menu.propTypes = {
  onSignOut: PropTypes.func.isRequired,
};
