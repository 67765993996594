import React from 'react';
import { makeStyles, Paper, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  informationCard: {
    boxShadow: theme.shadows.small,
    borderRadius: '4px',
    background: theme.palette.complementColors.lightYellow,
    padding: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
}));

export default function SettingsInformation() {
  const classes = useStyles();
  const { t } = useTranslation('settings');

  return (
    <Paper elevation={0} className={classes.informationCard}>
      <Typography variant='h5' gutterBottom>
        {t('customize')}
      </Typography>
      <Typography>{t('customizeBody')}</Typography>
    </Paper>
  );
}
