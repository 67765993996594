import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Route, Switch } from 'react-router-dom';
import Main from './Main';
import Login from './Login/index';
import DataService from '../Services/DataService';
import { MenuProvider } from '../Contexts/MenuContext';
import { UserProvider } from '../Contexts/UserContext';
import CacheBuster from '../Components/CacheBuster';
import { SnackbarProvider } from '../Contexts/SnackbarContext';
import CostCalculator from './CostCalculator';
import { UserManager } from '../Services/UserManager';
export default function App() {
  const [signedInState, setSignedInState] = useState('');
  const refreshTokenTimer = useRef(null);

  const startRefreshTokenTimer = useCallback(() => {
    if (refreshTokenTimer) {
      clearInterval(refreshTokenTimer);
      refreshTokenTimer.current = null;
    }
    refreshTokenTimer.current = setInterval(() => {
      DataService.checkToken(true)
        .then((result) => {
          if (false === result) {
            // failed to refresh token, expired or server error
            setSignedInState('signedOut');
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }, process.env.REACT_APP_TOKEN_REFRESH_INTERVAL_MINUTES * 60 * 1000);
  }, []);

  const stopRefreshTokenTimer = useCallback(() => {
    if (null !== refreshTokenTimer.current) {
      clearInterval(refreshTokenTimer.current);
      refreshTokenTimer.current = null;
    }
  }, []);

  useEffect(() => {
    DataService.checkToken(true)
      .then((result) => {
        setSignedInState(result ? 'signedIn' : 'signedOut');
        if (true === result) {
          startRefreshTokenTimer();
        }
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      stopRefreshTokenTimer();
    };
  }, [startRefreshTokenTimer, stopRefreshTokenTimer]);

  useEffect(() => {
    const interval = setInterval(() => {
      const ssoToken = UserManager.getIdToken();

      if (signedInState === 'signedIn' && !ssoToken) {
        onSignOut();
      }

      if (signedInState === 'signedOut' && ssoToken) {
        onSuccessfulSignIn({ token: ssoToken });
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  function onSignOut() {
    UserManager.removeUser();
    setSignedInState('signedOut');
    stopRefreshTokenTimer();
  }

  function onPendingSignIn() {
    setSignedInState('pending');
  }

  function onForbiddenSignIn() {
    setSignedInState('forbidden');
  }

  /**
   * @summary A callback to handle when the user successfully login with email and password.
   */
  function onSuccessfulSignIn(user) {
    // Clear local storage of expired token
    UserManager.removeUser();
    UserManager.setSSOToken(user);
    DataService.checkToken(true)
      .then((result) => {
        setSignedInState(result ? 'signedIn' : 'signedOut');
        if (true === result) {
          startRefreshTokenTimer();
        } else {
          setSignedInState('forbidden');
        }
      })
      .catch((e) => {
        setSignedInState('forbidden');
        console.log(e);
      });
  }

  function onFailedSignIn() {
    setSignedInState('failed');
  }

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;

        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return signedInState === '' ? null : (
          <Switch>
            <Route path='/login'>
              <SnackbarProvider>
                <Login
                  successfulSignInCallback={(user) => onSuccessfulSignIn(user)}
                  onPendingSignInCallback={() => onPendingSignIn()}
                  failedSignInCallback={() => onFailedSignIn()}
                  forbiddenSignInCallback={() => onForbiddenSignIn()}
                  signedInState={signedInState}
                />
              </SnackbarProvider>
            </Route>
            <Route path='/cost' component={CostCalculator} />
            {/* This route needs to be last since it would match first otherwise */}
            <Route path='/'>
              <SnackbarProvider>
                <UserProvider
                  userSiteId={localStorage.getItem('siteid')}
                  userName={localStorage.getItem('name')}
                  userId={localStorage.getItem('userid')}
                >
                  <MenuProvider>
                    <Main signedInState={signedInState} onSignOutCallback={() => onSignOut()} />
                  </MenuProvider>
                </UserProvider>
              </SnackbarProvider>
            </Route>
          </Switch>
        );
      }}
    </CacheBuster>
  );
}
