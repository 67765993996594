import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery, makeStyles, Typography } from '@material-ui/core';
import { HeaderContext } from '../../Contexts/HeaderContext';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import MenuIcon from '@material-ui/icons/Menu';
import SelectLanguageFlag from '../SelectLanguageFlag';

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    boxSizing: 'border-box',
    position: 'relative',
    minHeight: 'var(--topbar-height)',
    padding: '0 1rem 0 1rem',

    '@media (max-width: 1160px)': {
      minHeight: 'var(--mobile-topbar-height)',
    },
  },
  headerEnd: {
    display: 'flex',
    '& > svg + div': {
      marginLeft: '16px',
    },
  },
  headerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  menuIcon: {
    color: '#FFFFFF',
    marginRight: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  questionIcon: {
    color: '#FFFFFF',
    cursor: 'pointer',
  },
  headerText: {
    color: '#FFFFFF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  headerTextWrapper: {
    maxWidth: '85%',
  },
}));

export default function Header(props) {
  const toggleMenu = props.toggleMenu;
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { showQuestionmark, setShowInfoBox, headerTitle } = useContext(HeaderContext);
  const classes = useStyles();

  return (
    <div className={classes.header}>
      {toggleMenu && isMobile && (
        <MenuIcon fontSize='large' className={classes.menuIcon} onClick={toggleMenu} />
      )}
      <div className={classes.headerContent}>
        <div className={classes.headerTextWrapper}>
          <Typography variant={isMobile ? 'h5' : 'h4'} className={classes.headerText}>
            {headerTitle}
          </Typography>
        </div>
        <div className={classes.headerEnd}>
          {showQuestionmark && (
            <HelpOutlineIcon
              fontSize={isMobile ? 'medium' : 'large'}
              className={classes.questionIcon}
              onClick={() => setShowInfoBox(true)}
            />
          )}
          {(!isMobile || window.location.pathname === '/login') && <SelectLanguageFlag />}
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  toggleMenu: PropTypes.func,
};
