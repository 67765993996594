import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  useMediaQuery,
  Dialog,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  CircularProgress,
} from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import PropTypes from 'prop-types';
import {
  getPreparationOptions,
  getSampleMethodOptions,
} from '../../../Services/Utility/MeasurementInformation';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1.5),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    minWidth: '15rem',
  },
  dialogTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  selectWrapper: {
    marginTop: theme.spacing(1.75),
  },
  selectHeader: {
    marginBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexGrow: 1,
    alignItems: 'flex-end',
    marginTop: theme.spacing(2),
  },
  optionsWrapper: {
    '& .MuiFormGroup-root': {
      '& label+label': {
        marginTop: theme.spacing(0.75),
      },
    },
  },
  confirmWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    justifyContent: 'center',
    marginBottom: theme.spacing(4),
  },
  confirmIcon: {
    height: theme.spacing(3.125),
    width: theme.spacing(3.125),
    marginBottom: theme.spacing(1.75),
  },
  closeDialogButtonWrapper: {
    position: 'absolute',
    bottom: theme.spacing(1),
    boxSizing: 'border-box',
    width: '100%',
    padding: theme.spacing(0, 1),
  },
  supportInfo: {
    marginTop: theme.spacing(1.5),
  },
  loadingSpinner: {
    color: '#FFFFFF',
    height: '20px !important',
    width: '20px !important',
  },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
}));

export default function OnBoarding(props) {
  const { currentSiteSettings, isOnboardingComplete, onSavedSettingsSuccessfully } = props;
  const { t } = useTranslation('settings');
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const preparationOptions = getPreparationOptions();
  const methodOptions = getSampleMethodOptions();

  const [selectedPreparation, setSelectedPreparation] = useState(undefined);
  const [selectedMethod, setSelectedMethod] = useState(undefined);
  const [confirmed, setConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentSiteSettings?.energyType) {
      setSelectedPreparation(currentSiteSettings.energyType.toString());
    } else {
      // pick first item in list
      setSelectedPreparation('1');
    }

    if (currentSiteSettings?.measurementPackage) {
      setSelectedMethod(currentSiteSettings.measurementPackage.toString());
    } else {
      // pick first item in list
      setSelectedMethod('1');
    }
  }, [currentSiteSettings]);

  function handleChangePreparation(e) {
    setSelectedPreparation(e.target.value);
  }

  function handleChangeMethod(e) {
    setSelectedMethod(e.target.value);
  }

  function onConfirmSettings() {
    if (selectedPreparation && selectedMethod) {
      saveSettings(parseInt(selectedPreparation), parseInt(selectedMethod));
    }
  }

  async function saveSettings(preparation, method) {
    setIsLoading(true);
    const url = process.env.REACT_APP_API_URL;
    const auth = {
      headers: {
        'Cache-Control': 'no-cache',
        authorization: 'Bearer ' + localStorage.getItem('token'),
        SiteId: localStorage.getItem('siteid'),
      },
    };

    const preparationPromise = axios.put(
      url + '/KetoskollSiteSettings/SetSiteEnergyType',
      {
        userId: localStorage.getItem('userid'),
        siteId: localStorage.getItem('siteid'),
        energyType: preparation,
        modifiedat: new Date(),
        modifiedby: localStorage.getItem('userid'),
      },
      auth,
    );

    const methodPromise = axios.put(
      url + '/KetoskollSiteSettings/SetMeasurementPackageType',
      {
        siteId: localStorage.getItem('siteid'),
        userId: localStorage.getItem('userid'),
        measurementPackageType: method,
        modifiedat: new Date(),
        modifiedby: localStorage.getItem('userid'),
      },
      auth,
    );

    Promise.all([preparationPromise, methodPromise])
      .then((response) => {
        setConfirmed('success');
        setIsLoading(false);
      })
      .catch((err) => {
        setConfirmed('error');
        setIsLoading(false);
        console.log('Error occurred when trying to save onboarding settings', err);
      });
  }

  const SelectPreparation = () => {
    return (
      <div className={classes.selectWrapper}>
        <Typography variant='h5' className={classes.selectHeader}>
          {t('selectPreparation')}
        </Typography>
        <FormControl component='fieldset' classes={{ root: classes.optionsWrapper }}>
          <RadioGroup
            aria-label='gender'
            name='gender1'
            value={selectedPreparation}
            onChange={handleChangePreparation}
          >
            {preparationOptions.map((option) => {
              return (
                <FormControlLabel
                  key={option.key}
                  value={option.value.toString()}
                  control={<Radio color='primary' />}
                  label={option.title}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const SelectMethod = () => {
    return (
      <div className={classes.selectWrapper}>
        <Typography variant='h5' className={classes.selectHeader}>
          {t('selectMethod')}
        </Typography>
        <FormControl component='fieldset' classes={{ root: classes.optionsWrapper }}>
          <RadioGroup
            aria-label='gender'
            name='gender1'
            value={selectedMethod}
            onChange={handleChangeMethod}
          >
            {methodOptions.map((option) => {
              return (
                <FormControlLabel
                  key={option.key}
                  value={option.value.toString()}
                  control={<Radio color='primary' />}
                  label={option.title}
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </div>
    );
  };

  const Confirmed = () => {
    return (
      <div className={classes.confirmWrapper}>
        {confirmed === 'success' ? (
          <>
            <CheckCircleOutlineIcon color='primary' className={classes.confirmIcon} />
            <Typography variant='subtitle1'>{t('savedSettings')}</Typography>
          </>
        ) : (
          <>
            <CancelOutlinedIcon color='secondary' className={classes.confirmIcon} />
            <Typography variant='subtitle1'>{t('somethingWentWrong')}</Typography>
            <div className={classes.supportInfo}>
              <Typography variant='subtitle1'>{t('tryAgain')}</Typography>
              <div className={classes.contact}>
                <Typography variant='subtitle1' component='a' key='Phone' href='tel:+46768619908'>
                  +4676-861 99 08
                </Typography>
                <Typography
                  variant='subtitle1'
                  component='a'
                  key='Email'
                  href='https://knowledge.agricam.se/knowledge/kb-tickets/new'
                  target='_blank'
                  rel='noreferrer'
                >
                  support@agricam.se
                </Typography>
              </div>
            </div>
          </>
        )}
        <div className={classes.closeDialogButtonWrapper}>
          <Button
            variant='contained'
            disableElevation
            color='primary'
            size='large'
            fullWidth
            onClick={() => {
              if (confirmed === 'success') {
                onSavedSettingsSuccessfully();
              } else {
                setConfirmed(false);
              }
            }}
          >
            {t('close')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Dialog open={!isOnboardingComplete} fullScreen={isMobile}>
      <div className={classes.dialog}>
        {confirmed ? (
          <Confirmed />
        ) : (
          <>
            <div className={classes.dialogTitleWrapper}>
              <HelpOutlineIcon />
              <Typography variant='subtitle1'>{t('customize')}</Typography>
            </div>
            <Typography>{t('customizeBody')}</Typography>
            <SelectPreparation />
            <SelectMethod />
            <div className={classes.buttonContainer}>
              <Button
                variant='contained'
                fullWidth={isMobile}
                disableElevation
                color='primary'
                size='large'
                onClick={onConfirmSettings}
                startIcon={
                  isLoading ? <CircularProgress className={classes.loadingSpinner} /> : <></>
                }
              >
                {t('confirm')}
              </Button>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
}

OnBoarding.propTypes = {
  currentSiteSettings: PropTypes.object.isRequired,
  isOnboardingComplete: PropTypes.bool.isRequired,
  onSavedSettingsSuccessfully: PropTypes.func.isRequired,
};
