/* eslint-disable no-dupe-keys */
// This component is only used on the Agricam homepage

import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    padding: '1rem',
  },
  form: {
    width: '100%',
    fontFamily: 'Open sans',
    color: 'black',
  },

  field: {
    marginBottom: '10px',
    flexGrow: 1,

    ' & label': {
      cursor: 'default',
    },

    ' & output': {
      display: 'block',
      fontSize: '14px',
      lineHeight: '1.42857',
      color: '#555',
      verticalAlign: 'middle',
    },
  },

  ranger: {
    boxSizing: 'border-box',
    color: '#555',
    width: '100%',
    padding: '0.5rem 0',
  },

  textInput: {
    boxSizing: 'border-box',
    display: 'block',
    width: '100%',
    height: '34px',
    padding: '6px 12px',
    fontSize: '14px',
    lineHeight: 1.42857143,
    color: '#555',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    '-webkit-box-shadow': 'inset 0 1px 1px rgb(0 0 0 / 8%)',
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
    '-webkit-transition': 'border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s',
    '-o-transition': 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
    transition: 'border-color ease-in-out .15s, box-shadow ease-in-out .15s',
  },

  consent: {
    display: 'flex',
    margin: '20px 0',
    alignItems: 'center',
    flexGrow: 1,
  },

  checkBox: {
    minWidth: '14px',
    marginRight: '1rem',
    boxSizing: 'border-box',
  },

  submitButton: {
    padding: '10px 16px',
    fontSize: '18px',
    lineHeight: '1.33',
    background: '#DB6014',
    color: '#FFF',
    borderRadius: '2px',
    border: '0px',
    display: 'block',
    width: '100%',
  },

  select: {
    display: 'block',
    fontSize: '14px',
    lineHeight: '1.42857',
    color: '#555',
    verticalAlign: 'middle',

    width: '100%',
    height: '34px',
    padding: '6px 8px',
    backgroundColor: '#fff',
    backgroundImage: 'none',
    border: '1px solid #ccc',
    borderRadius: '4px',
    '-webkit-box-shadow': 'inset 0 1px 1px rgb(0 0 0 / 8%)',
    boxShadow: 'inset 0 1px 1px rgb(0 0 0 / 8%)',
    '-webkit-transition': 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    '-webkit-transition': 'border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out',
    transition: 'border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out',
    transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    transition:
      'border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out',
  },
});

export default function CostCalculator() {
  const portalId = '24933022'; // The HubSpot portal the form belongs to.
  const formId = 'c8fcd884-5aea-4fea-846f-56f1765e05d5'; // The ID of the form you're sending data to.
  const targetOrigin = 'https://www.agricam.se';
  const consentText =
    'Jag har tagit del av integritetspolicyn och samtycker till behandling av mina personuppgifter.';
  const readPolicy = 'Läs integritetspolicyn';
  const regions = [
    'Stockholm',
    'Uppsala',
    'Sörmland',
    'Östergötland',
    'Jönköpings län',
    'Kronoberg',
    'Kalmar län',
    'Gotland',
    'Blekinge',
    'Skåne',
    'Halland',
    'Västra götaland',
    'Värmland',
    'Örebro län',
    'Västmanland',
    'Dalarna',
    'Gävleborg',
    'Västernorrland',
    'Jämtland',
    'Västerbotten',
    'Norrbotten',
  ];

  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [cowQuantity, setCowQuantity] = useState(200);
  const [consent, setConsent] = useState(false);
  const [region, setRegion] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const classes = useStyles();

  const submitData = useCallback(async () => {
    const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`;

    const hubspotResponse = await axios.post(url, {
      fields: [
        {
          objectTypeId: '0-1',
          name: 'antal_kor',
          value: cowQuantity,
        },
        {
          objectTypeId: '0-1',
          name: 'state',
          value: region,
        },
        {
          objectTypeId: '0-1',
          name: 'email',
          value: email,
        },
        {
          objectTypeId: '0-1',
          name: 'company',
          value: company,
        },
        {
          objectTypeId: '0-1',
          name: 'phone',
          value: phoneNumber,
        },
      ],
      legalConsentOptions: {
        consent: {
          consentToProcess: consent,
          text: consentText,
        },
      },
    });

    if (!hubspotResponse) {
      return;
    }

    if (hubspotResponse instanceof Error) {
      console.log('Error submitting hubspot form');
    } else {
      window.parent.postMessage('formIsValidated', targetOrigin);
    }
  }, [company, consent, cowQuantity, email, phoneNumber, region]);

  const onSubmit = useCallback(
    (e) => {
      // Prevent page refresh
      e.preventDefault();

      submitData();
    },
    [submitData],
  );

  function onClick() {
    // Always pass current cow quantity value when hitting "Submit"
    window.parent.postMessage(cowQuantity, targetOrigin);
  }

  return (
    <>
      <div className={classes.container} id='cost-calculator-container'>
        <form onSubmit={onSubmit} onInput={() => parseInt(cowQuantity)} className={classes.form}>
          {/* CowQuantity */}
          <div className={classes.field}>
            <label>Antal djur i min besättning *</label>
            <input
              type='range'
              className={classes.ranger}
              id='cowquantity'
              onChange={(e) => setCowQuantity(e.target.value)}
              value={cowQuantity}
              min={1}
              max={1200}
            />
            <div>
              <output name='result' htmlFor='cowquantity'>
                {cowQuantity}
                <span> årskor</span>
              </output>
            </div>
          </div>

          {/* Region */}
          <div className={classes.field}>
            <label>
              Region *
              <select
                required
                value={region}
                onChange={(e) => setRegion(e.target.value)}
                className={classes.select}
              >
                <option value='' disabled>
                  Välj region...
                </option>
                {regions.map((r) => (
                  <option value={r} key={r}>
                    {r}
                  </option>
                ))}
              </select>
            </label>
          </div>

          {/* Email */}
          <div className={classes.field}>
            <label>
              E-post *
              <input
                required='required'
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={classes.textInput}
              />
            </label>
          </div>

          {/* Company name */}
          <div className={classes.field}>
            <label>
              Företag
              <input
                type='text'
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                className={classes.textInput}
              />
            </label>
          </div>

          {/* Phone number */}
          <div className={classes.field}>
            <label>
              Telefonnummer
              <input
                type='text'
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={classes.textInput}
              />
            </label>
          </div>

          {/* Consent checkbox */}
          <div className={classes.consent}>
            <input
              type='checkbox'
              value={consent}
              onChange={(e) => setConsent(!consent)}
              className={classes.checkBox}
              required='required'
            />
            <label>
              {consentText}{' '}
              <a
                href='https://www.agricam.se/integritetspolicy'
                target='_blank'
                rel='noreferrer noopener'
              >
                {readPolicy}
              </a>{' '}
              *
            </label>
          </div>

          <input
            type='submit'
            value='Visa mitt resultat'
            className={classes.submitButton}
            onClick={onClick}
          />
        </form>
      </div>

      {/* Iframe testing */}
      {/* <div
        style={{
          marginTop: '3rem',
          padding: '1rem',
          overflow: 'hidden',
          height: '600px',
        }}
      >
        <iframe
          scrolling='no'
          allowFullScreen
          src='http://localhost:3001/cost'
          style={{ width: '100%', height: '100%', border: 'none' }}
        ></iframe>
      </div> */}
    </>
  );
}
