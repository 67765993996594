/**
 * Translations and interpretations of database-values
 */
export const enums = {
  System: {
    Ketoskoll: 'df3487ef-0c3d-46e7-b83d-1e14c8083d1b',
  },
  MeasurementReason: {
    NotSet: 0,
    Symptom: 1,
    OtherReason: 2,
    StrategicSelection: 3,
    FirstTimeCalver: 4,
    KnownKetosisReason: 5,
  },
  KetosisMeasurementResult: {
    NotSet: 0,
    Normal: 1,
    EarlyKetosis: 2,
    LateKetosis: 3,
    SuspectedKetosis: 4,
  },
  BhbMeasurementMethod: {
    NotSet: 0,
    Milk: 1,
    Blood: 2,
  },
  InfoType: {
    Form: 0,
    ReasonType: 1,
    ResultType: 2,
  },
  actionType: {
    counselings: 'counselings',
    treatments: 'treatments',
    tests: 'tests',
  },
  actionName: {
    giveCalciumAndPG: 'giveCalciumAndPG',
    giveCalciumAndGlycerol: 'giveCalciumAndGlycerol',
    givePG: 'givePG',
    giveGlycerol: 'giveGlycerol',
    takeBloodSample: 'takeBloodSample',
    takeMilkSample: 'takeMilkSample',
    stopIneffectiveTreatment: 'stopIneffectiveTreatment',
    giveCalciumAndOtherEnergy: 'giveCalciumAndOtherEnergy',
    giveOtherEnergy: 'giveOtherEnergy',
  },
  actionPackage: {
    NewEarlyKetosisPropyleneGlycol: 'd5858ea1-0a83-400b-ac87-0523a65ceb98',
    NewEarlyKetosisPropyleneGlycolMilk: 'a339ff9c-6776-445d-8edb-847b8a5ba5ec',
    EarlyKetosisPropyleneGlycol: '1b3f8609-c718-4a92-a259-e17a0da46be6',
    EarlyKetosisPropyleneGlycolMilk: 'ed914803-7707-4a7d-9cee-e28a16d11961',
    NewEarlyKetosisGlycerol: '27f39f4b-a80e-4c0e-a68c-14279bfcc305',
    NewEarlyKetosisGlycerolMilk: 'e3ca5706-e1b5-45dc-9b94-f88f821be105',
    EarlyKetosisGlycerol: 'db87f3c9-1be0-4d4f-968f-2dc00b1dcd7e',
    EarlyKetosisGlycerolMilk: '1518bf7d-c33f-4cf8-b1d5-56467bb45974',
    NewEarlyKetosisOtherEnergy: 'cf96e2ae-d03a-45e0-8fa5-be8c63bc5765',
    NewEarlyKetosisOtherEnergyMilk: '1e22fbd4-cadc-4906-a35c-c34e2e82d636',
    EarlyKetosisOtherEnergy: 'b8f53153-9ad1-4b3e-90db-02a5afdd65d2',
    EarlyKetosisOtherEnergyMilk: 'b0fc3f54-4c4c-4271-a766-09de6d190ad4',
    NewLateKetosisPropyleneGlycol: 'a3a0a3a5-5268-439f-9f73-acdf29e62faa',
    NewLateKetosisPropyleneGlycolMilk: '7fe12a10-ed91-4fd9-9721-c7029ef94251',
    LateKetosisPropyleneGlycol: 'a704b1fc-fdb8-40ab-a362-bd70bcbba23a',
    LateKetosisPropyleneGlycolMilk: 'db1f6ef9-4274-42f5-b78d-547d83fe4d23',
    NewLateKetosisGlycerol: '1bf42e98-a59d-4310-a936-b6a339ad0c6b',
    NewLateKetosisGlycerolMilk: '3f8e0b95-3e4a-4e18-8435-680d52dba362',
    LateKetosisGlycerol: '94a37fb3-ccea-492d-af52-0f54e258539e',
    LateKetosisGlycerolMilk: '5c44521b-c89d-4175-80f2-2e8bde76c044',
    NewLateKetosisOtherEnergy: '0ed40a1c-24b6-4491-8d47-d61820b14414',
    NewLateKetosisOtherEnergyMilk: '169d13e8-7647-4b56-9405-a80940dedd69',
    LateKetosisOtherEnergy: '13d72de1-5eb7-4268-9878-73244c7204e8',
    LateKetosisOtherEnergyMilk: '4e050313-d5ba-41ba-a68a-d59ce3226a87',
    SuspectedKetosisBlood: '20fa91f9-b0d9-4c2f-9be4-72ac935cea03',
    SuspectedKetosisMilk: '981c836c-0246-40db-a071-37a95c02a2f2',
    StopIneffectiveTreatment: 'dcb9d924-91e4-43db-9a51-ac62f2655455',
    HealthyAfterKetosis: '65f6c78b-a9a0-4622-8afa-1e896e03dcdc',
    NoKetosis: 'ab63ce68-30f1-468c-931a-da193a1b2ecd',
    LateRegistrationNewLateKetosis: 'c39e7d3e-2b46-4d36-bc5b-4e77c52ebcc5',
    LateRegistrationNewEarlyKetosis: '7f30a6a7-6434-4114-a76d-690ee5aed0b6',
    LateRegistrationKetosis: '77b47c49-02cb-4131-9084-87a74b9e50be',
    LateRegistrationSuspectedKetosis: '2a0ad499-f8c0-49fa-8bd6-25d235c4a5d5',
  },
  actionPackageName: {
    NewEarlyKetosisPropyleneGlycol: 'NewEarlyKetosisPropyleneGlycol',
    NewEarlyKetosisPropyleneGlycolMilk: 'NewEarlyKetosisPropyleneGlycolMilk',
    EarlyKetosisPropyleneGlycol: 'EarlyKetosisPropyleneGlycol',
    EarlyKetosisPropyleneGlycolMilk: 'EarlyKetosisPropyleneGlycolMilk',
    NewEarlyKetosisGlycerol: 'NewEarlyKetosisGlycerol',
    NewEarlyKetosisGlycerolMilk: 'NewEarlyKetosisGlycerolMilk',
    EarlyKetosisGlycerol: 'EarlyKetosisGlycerol',
    EarlyKetosisGlycerolMilk: 'EarlyKetosisGlycerolMilk',
    NewEarlyKetosisOtherEnergy: 'NewEarlyKetosisOtherEnergy',
    NewEarlyKetosisOtherEnergyMilk: 'NewEarlyKetosisOtherEnergyMilk',
    EarlyKetosisOtherEnergy: 'EarlyKetosisOtherEnergy',
    EarlyKetosisOtherEnergyMilk: 'EarlyKetosisOtherEnergyMilk',
    NewLateKetosisPropyleneGlycol: 'NewLateKetosisPropyleneGlycol',
    NewLateKetosisPropyleneGlycolMilk: 'NewLateKetosisPropyleneGlycolMilk',
    LateKetosisPropyleneGlycol: 'LateKetosisPropyleneGlycol',
    LateKetosisPropyleneGlycolMilk: 'LateKetosisPropyleneGlycolMilk',
    NewLateKetosisGlycerol: 'NewLateKetosisGlycerol',
    NewLateKetosisGlycerolMilk: 'NewLateKetosisGlycerolMilk',
    LateKetosisGlycerol: 'LateKetosisGlycerol',
    LateKetosisGlycerolMilk: 'LateKetosisGlycerolMilk',
    NewLateKetosisOtherEnergy: 'NewLateKetosisOtherEnergy',
    NewLateKetosisOtherEnergyMilk: 'NewLateKetosisOtherEnergyMilk',
    LateKetosisOtherEnergy: 'LateKetosisOtherEnergy',
    LateKetosisOtherEnergyMilk: 'LateKetosisOtherEnergyMilk',
    SuspectedKetosisBlood: 'SuspectedKetosisBlood',
    SuspectedKetosisMilk: 'SuspectedKetosisMilk',
    StopIneffectiveTreatment: 'StopIneffectiveTreatment',
    HealthyAfterKetosis: 'HealthyAfterKetosis',
    NoKetosis: 'NoKetosis',
    LateRegistrationNewLateKetosis: 'LateRegistrationNewLateKetosis',
    LateRegistrationNewEarlyKetosis: 'LateRegistrationNewEarlyKetosis',
    LateRegistrationKetosis: 'LateRegistrationKetosis',
    LateRegistrationSuspectedKetosis: 'LateRegistrationSuspectedKetosis',
  },
  energyType: {
    notSet: 0,
    propyleneGlycol: 1,
    glycerol: 2,
    other: 3,
  },
  measurementPackageType: {
    notSet: 0,
    blood: 1,
    milk: 2,
    bloodAndMilk: 3,
  },
  sex: {
    notSet: 0,
    male: 1,
    female: 2,
  },
};
