import React, { useState } from 'react';
import { makeStyles, Paper, Typography, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ResultMeasurement from './ResultMeasurement';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import { getActionPackageType } from '../../../../Services/Utility/MeasurementInformation';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.5),
    borderRadius: '4px',
    boxShadow: theme.shadows.small,
  },
  headerIcon: {
    display: 'flex',
  },
  headerTextAndButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  subHeader: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(-0.75),
    color: theme.palette.grays.gray80,
    maxWidth: '90%',
  },
  cancelTreatment: {
    borderLeft: '3px solid black',
  },
  newTest: {
    borderLeft: `3px solid ${theme.palette.complementColors.blueGrey}`,
  },
  earlyKetosis: {
    borderLeft: `3px solid ${theme.palette.complementColors.darkRed}`,
  },
  lateKetosis: {
    borderLeft: `3px solid ${theme.palette.complementColors.darkYellow}`,
  },
  cancelTreatmentIcon: {
    display: 'flex',
    marginRight: '8px',
    color: 'black',
  },
  suspectedKetosisIcon: {
    display: 'flex',
    marginRight: '8px',
    color: theme.palette.complementColors.blueGrey,
  },
  earlyKetosisIcon: {
    display: 'flex',
    marginRight: '8px',
    color: theme.palette.complementColors.darkRed,
  },
  lateKetosisIcon: {
    display: 'flex',
    marginRight: '8px',
    color: theme.palette.complementColors.darkYellow,
  },
  noActionIcon: {
    display: 'flex',
    marginRight: '8px',
    color: theme.palette.complementColors.green,
  },
  lateRegistration: {
    borderLeft: `3px solid ${theme.palette.grays.gray40}`,
  },
  noAction: {
    borderLeft: `3px solid ${theme.palette.complementColors.green}`,
  },
  explanation: {
    background: theme.palette.grays.gray5,
    margin: theme.spacing(0, -1),
    padding: theme.spacing(1, 1),
  },
  iconButton: {
    color: 'black',
    marginLeft: 'auto',
  },
}));

export default function ActionPackage(props) {
  const { resultType, actionPackage, measurements } = props;
  const classes = useStyles();
  const { t } = useTranslation('actionPackage');
  const actionPackageType = actionPackage
    ? getActionPackageType(actionPackage.actionPackageId)
    : null;
  let cowCount = 0;

  const [showExplanation, setShowExplanation] = useState(false);

  function getActionPackageTypeColor() {
    switch (actionPackageType) {
      case 'suspectedKetosis':
        return classes.newTest;
      case 'earlyKetosis':
        return classes.earlyKetosis;
      case 'continuedKetosis':
        return classes.lateKetosis;
      case 'lateKetosis':
        return classes.lateKetosis;
      case 'treatmentIsNotWorking':
        return classes.cancelTreatment;
      case 'noKetosis':
        return classes.noAction;
      case 'recoveredFromKetosis':
        return classes.noAction;
      default:
        return '';
    }
  }

  const ActionPackageHeader = () => {
    return (
      <Typography variant='h5'>
        {t(`actionPackage:type:${actionPackageType}`, { count: cowCount })}
      </Typography>
    );
  };

  const ActionPackageExplanation = () => {
    return (
      <div className={classes.explanation}>
        <Typography>
          <Trans i18nKey={`actionPackage:${actionPackage.name}`} count={cowCount} />
        </Typography>
      </div>
    );
  };

  const HeaderIcon = () => {
    switch (actionPackageType) {
      case 'suspectedKetosis':
        return <HelpIcon className={classes.suspectedKetosisIcon} fontSize='large' />;
      case 'earlyKetosis':
        return <WarningIcon className={classes.earlyKetosisIcon} fontSize='large' />;
      case 'continuedKetosis':
        return <WarningIcon className={classes.lateKetosisIcon} fontSize='large' />;
      case 'lateKetosis':
        return <WarningIcon className={classes.lateKetosisIcon} fontSize='large' />;
      case 'treatmentIsNotWorking':
        return <CancelOutlinedIcon className={classes.cancelTreatmentIcon} fontSize='large' />;
      case 'noKetosis':
        return <CheckCircleOutlineIcon className={classes.noActionIcon} fontSize='large' />;
      case 'recoveredFromKetosis':
        return <CheckCircleOutlineIcon className={classes.noActionIcon} fontSize='large' />;
      default:
        return <></>;
    }
  };

  return (
    <Paper elevation={0} className={`${classes.card} ${getActionPackageTypeColor()}`}>
      <div>
        <div className={classes.headerTextAndButton}>
          <HeaderIcon />
          <ActionPackageHeader />
          {
            <IconButton
              classes={{ root: classes.iconButton }}
              onClick={() => setShowExplanation(!showExplanation)}
            >
              {showExplanation ? <CloseIcon /> : <InfoOutlinedIcon />}
            </IconButton>
          }
        </div>
        {actionPackageType === 'continuedKetosis' && resultType !== 'lateRegistration' && (
          <Typography className={classes.subHeader}>
            <i>{t('result:treatmentSubHeader')}</i>
          </Typography>
        )}
      </div>
      {showExplanation && <ActionPackageExplanation />}
      {measurements.map((measurement) => {
        cowCount++;
        return (
          <ResultMeasurement
            key={measurement.measurementId}
            actionPackage={actionPackage}
            measurement={measurement}
          />
        );
      })}
    </Paper>
  );
}

ActionPackage.propTypes = {
  resultType: PropTypes.string.isRequired,
  actionPackage: PropTypes.object.isRequired,
  measurements: PropTypes.array.isRequired,
};
