import React, { useState, useEffect, useContext, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import { Paper, Button, useMediaQuery, makeStyles, Typography } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { FormContext } from '../../Contexts/FormContext';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import animateScrollTo from 'animated-scroll-to';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import DateRangeIcon from '@material-ui/icons/DateRange';
import InfoBox from '../InfoBox';
import Cow from '../Cow';
import Confirmation from '../Confirmation';
import { enums } from '../../Services/Enumerations';
import DateFnsUtils from '@date-io/date-fns';
import svLocale from 'date-fns/locale/sv';
import frLocale from 'date-fns/locale/fr';
import DataService from '../../Services/DataService';
import { getDIM } from '../../Services/Utility/CowUtils';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContainer: {
    boxSizing: 'border-box',
    height: 'calc(100vh - 13rem)',
    paddingTop: '1rem',
    overflow: 'auto',
    flexGrow: 1,
  },
  desktopCardContainer: {
    width: '100%',
    padding: '2rem 25%',
  },
  cowContainer: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '0 1rem 1rem 1rem',
  },
  desktopCowContainer: {
    width: '100%',
  },
  footer: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  inputWrapper: {
    display: 'inline-block',
    width: '12rem',
    margin: '0.4rem',
  },

  addButton: {
    marginTop: '0.5rem',
    width: theme.spacing(8),
    float: 'right',
  },
  submit: {
    float: 'right',
    border: 'none',
    width: theme.spacing(8),
  },
  goBackButton: {
    backgroundColor: 'transparent',
    width: theme.spacing(8),
  },

  datePickerContainer: {
    padding: theme.spacing(1),
    borderRadius: '4px',
    boxShadow: theme.shadows.small,
    marginBottom: theme.spacing(1),
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& svg': {
      color: theme.palette.grays.gray100,
    },
  },
}));

export default function Form(props) {
  const { testType, reasonType, getResultsFromPostsCallback } = props;
  const { t, i18n } = useTranslation('formComponent');
  const isMobile = useMediaQuery('(max-width:1160px)');
  const form = useRef(null);
  const classes = useStyles();
  const { setShowQuestionmark, showInfoBox, setHeaderTitle } = useContext(HeaderContext);
  const {
    cowState,
    setCowState,
    setExistingCows,
    selectedDate,
    setSelectedDate,
    setPPN,
  } = useContext(FormContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const [formActive, setFormActive] = useState(true);
  const [moveOnDisabled, setMoveOnDisabled] = useState(false);
  const [pressedContinueButton, setPressedContinueButton] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const blankCow = {
    measuredAt: selectedDate,
    identifier: '', // cow number (string)
    daysInMilk: '',
    bhbValue: '',
    measurementMethod: testType, // blood or milk test
    reasons: [
      // strategic or symptom
      reasonType,
    ],
    note: '',
    prefix: '',
    birthPlaceId: '',
    checkSum: '',
    animalNumber: '',
    lastCalvingDate: '',
  };

  const resetDateAndForm = useCallback(() => {
    setSelectedDate(new Date());
    setCowState([]);
  }, [setCowState, setSelectedDate]);

  // Reset SelectedDate and CowState when first visiting /form page
  useEffect(() => {
    resetDateAndForm();
  }, [resetDateAndForm]);

  // Show info question mark in header if the form is rendered
  useEffect(() => {
    setShowQuestionmark(formActive);
  }, [setShowQuestionmark, formActive]);

  // Make sure the list always has at least one card
  useEffect(() => {
    if (!cowState.length) {
      setCowState([...cowState, blankCow]);
    }
  }, [setCowState, cowState, blankCow]);

  // Check if we have all data needed to move on. Check so only bhbValue when bloodtest can enter non integers
  useEffect(() => {
    const isDisabled = cowState.some((cow) => {
      const milkTestError =
        cow.measurementMethod === enums.BhbMeasurementMethod.Milk &&
        !Number.isInteger(Number.parseFloat(cow.bhbValue));
      return (
        !Number.isInteger(Number.parseFloat(cow.identifier)) ||
        !Number.isInteger(Number.parseFloat(cow.daysInMilk)) ||
        Number.isNaN(Number.parseFloat(cow.bhbValue)) ||
        Math.sign(Number.parseFloat(cow.identifier)) !== 1 ||
        Math.sign(cow.daysInMilk) < 0 ||
        Math.sign(Math.floor(cow.bhbValue)) < 0 ||
        milkTestError ||
        !Number.isInteger(Number.parseFloat(cow.birthPlaceId)) ||
        Math.sign(Math.floor(cow.birthPlaceId)) < 0 ||
        Math.sign(cow.birthPlaceId) !== 1 ||
        !cow.prefix
      );
    });
    setMoveOnDisabled(isDisabled);
    setPressedContinueButton(false);
  }, [cowState]);

  // make sure cows are up to date with the selected test type and reason type
  useEffect(() => {
    setCowState((prevCowState) => {
      const tempCows = prevCowState.concat([]);
      return tempCows.map((cow) => {
        cow.measurementMethod = testType;

        if (enums.MeasurementReason.StrategicSelection === reasonType) {
          cow.reasons = cow.reasons.filter((reason) => reason !== enums.MeasurementReason.Symptom);
          if (
            !cow.reasons.some((reason) => reason === enums.MeasurementReason.StrategicSelection)
          ) {
            cow.reasons.push(enums.MeasurementReason.StrategicSelection);
          }
        } else if (enums.MeasurementReason.Symptom === reasonType) {
          cow.reasons = cow.reasons.filter(
            (reason) => reason !== enums.MeasurementReason.StrategicSelection,
          );
          if (!cow.reasons.some((reason) => reason === enums.MeasurementReason.Symptom)) {
            cow.reasons.push(enums.MeasurementReason.Symptom);
          }
        }

        return cow;
      });
    });
  }, [setCowState, testType, reasonType]);

  // Scroll effect when list grows/shrinks
  useEffect(() => {
    // Don't try to animate scroll if the ref is gone
    if (null === form.current) {
      return;
    }
    animateScrollTo(form.current.scrollHeight, {
      elementToScroll: form.current,
      speed: 400,
    });
  }, [cowState.length]);

  // Set header title
  useEffect(() => {
    let testTypeString = '';
    let reasonTypeString = '';

    switch (testType) {
      case enums.BhbMeasurementMethod.Blood:
        testTypeString = t('blood');
        break;
      case enums.BhbMeasurementMethod.Milk:
        testTypeString = t('milk');
        break;
      default:
        break;
    }

    switch (reasonType) {
      case enums.MeasurementReason.StrategicSelection:
        reasonTypeString = t('strategic');
        break;
      case enums.MeasurementReason.Symptom:
        reasonTypeString = t('suspicion');
        break;
      default:
        break;
    }

    setHeaderTitle(`${testTypeString} - ${reasonTypeString}`);
    document.title = `${`${testTypeString} - ${reasonTypeString}`} | KetosKoll`;
  }, [testType, reasonType, setHeaderTitle, t]);

  useEffect(() => {
    async function getCows() {
      const cows = await DataService.fetchDataById('Cow/GetCows', '');

      if (cows instanceof Error) {
        setSnackbar({ text: 'fetchCowsFailed', severity: 'warning', open: true });
      } else {
        const filteredCows = cows
          .filter((cow) => !cow.deathDate)
          .filter((cow) => cow.sex === enums.sex.female);
        setExistingCows(filteredCows);
      }
    }

    getCows();
  }, [setExistingCows, setSnackbar]);

  useEffect(() => {
    async function getPPN() {
      const areas = await DataService.fetchDataById('Area/GetAreasForSite', '');

      if (areas instanceof Error) {
        // Dont confuse user with a snackbar?
      } else {
        setPPN(areas.find((area) => area.ppn !== null)?.ppn);
      }
    }

    getPPN();
  }, [setPPN, setSnackbar]);

  const addCow = () => {
    setCowState([...cowState, blankCow]);
  };

  const deleteCow = (idx) => {
    const tempCowState = cowState.concat([]);
    tempCowState.splice(idx, 1);
    setCowState(tempCowState);
  };

  const updateCowProperty = (idx, propertyName, value) => {
    const tempCows = cowState.concat([]);
    tempCows[idx][propertyName] = value;
    setCowState(tempCows);
  };

  const toggleCowReason = (idx, reason) => {
    const tempCows = cowState.concat([]);
    if (tempCows[idx].reasons.some((existingReason) => existingReason === reason)) {
      tempCows[idx].reasons = tempCows[idx].reasons.filter(
        (existingReason) => existingReason !== reason,
      );
    } else {
      tempCows[idx].reasons.push(reason);
    }
    setCowState(tempCows);
  };

  const moveOn = () => {
    setPressedContinueButton(true);
    if (!moveOnDisabled) {
      setFormActive(false);
      setPressedContinueButton(false);
    }
  };

  const goBack = () => {
    setFormActive(true);
  };

  const send = () => {
    getResultsFromPostsCallback(cowState, selectedDate);
    setCowState([]);
  };

  const handleDateChange = (date) => {
    const now = new Date();
    const newDate = date < now ? date : now;

    setSelectedDate(newDate);

    // Update all DIM fields for cows where we know the last calving date.
    // If we don't know the last calving date, the DIM field will be cleared for those cows.
    const cowsWithUpdatedDIMs = cowState.map((cow) => {
      return {
        ...cow,
        daysInMilk: cow.lastCalvingDate ? getDIM(new Date(cow.lastCalvingDate), newDate) : '',
      };
    });

    setCowState(cowsWithUpdatedDIMs);
  };

  // If the page is refreshed, the props are gone and we need to redirect back to start
  if (
    testType === enums.BhbMeasurementMethod.NotSet ||
    reasonType === enums.MeasurementReason.NotSet
  ) {
    return <Redirect to='/new-test' />;
  }

  const AddButton = () => {
    return (
      <Button
        className={classes.addButton}
        onClick={addCow}
        variant='outlined'
        color='primary'
        startIcon={<AddCircleIcon />}
        size='large'
      >
        <Typography variant='h5'>{t('addCow')}</Typography>
      </Button>
    );
  };

  const GoBackToFormButton = () => {
    return (
      <Button
        onClick={goBack}
        variant='outlined'
        color='primary'
        size='large'
        className={classes.goBackButton}
      >
        <Typography variant='h5'>{t('goBack')}</Typography>
      </Button>
    );
  };

  const GoBackToReasonButton = () => {
    return (
      <Button
        className={classes.goBackButton}
        variant='outlined'
        color='primary'
        component={Link}
        to='/reason'
        size='large'
      >
        <Typography variant='h5'>{t('goBack')}</Typography>
      </Button>
    );
  };

  const SendButton = () => {
    return (
      <Button
        className={classes.submit}
        variant='contained'
        color='primary'
        onClick={send}
        component={Link}
        to='/result'
        size='large'
        disableElevation
        startIcon={<SendOutlinedIcon fontSize='small' />}
      >
        <Typography variant='h5'>{t('send')}</Typography>
      </Button>
    );
  };

  const MoveOnButton = () => {
    return (
      <Button
        className={classes.submit}
        variant='contained'
        color='primary'
        onClick={moveOn}
        size='large'
        disableElevation
      >
        <Typography variant='h5'>{t('moveOn')}</Typography>
      </Button>
    );
  };

  const DatePicker = () => {
    return (
      <Paper elevation={0} className={classes.datePickerContainer}>
        <Typography variant='body2'>{t('testDate')}</Typography>
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={i18n.language === 'sv' ? svLocale : i18n.language === 'fr' ? frLocale : null}
        >
          <KeyboardDatePicker
            disableToolbar
            variant='inline'
            format='yyyy-MM-dd'
            margin='normal'
            id='testDate'
            value={selectedDate}
            onChange={handleDateChange}
            keyboardIcon={<DateRangeIcon />}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Paper>
    );
  };

  return (
    <div className={classes.wrapper}>
      {showInfoBox ? <InfoBox infoType={enums.InfoType.Form} /> : <> </>}
      {formActive ? (
        <>
          <div
            className={
              isMobile
                ? classes.cardContainer
                : `${classes.cardContainer} ${classes.desktopCardContainer}`
            }
            ref={form}
          >
            <div
              className={
                isMobile
                  ? classes.cowContainer
                  : `${classes.cowContainer} ${classes.desktopCowContainer}`
              }
            >
              <DatePicker />
              {cowState.map((cow, idx) => {
                return (
                  <Cow
                    key={`cow-${idx}`}
                    cowData={cow}
                    cowIndex={idx}
                    deleteCow={deleteCow}
                    updateCowProperty={updateCowProperty}
                    toggleCowReason={toggleCowReason}
                    pressedContinueButton={pressedContinueButton}
                  />
                );
              })}
              <AddButton />
            </div>
          </div>
          <div className={classes.footer}>
            <GoBackToReasonButton />
            <MoveOnButton />
          </div>
        </>
      ) : (
        <>
          <div
            className={
              isMobile
                ? classes.cardContainer
                : `${classes.cardContainer} ${classes.desktopCardContainer}`
            }
            ref={form}
          >
            <div
              className={
                isMobile
                  ? classes.cowContainer
                  : `${classes.cowContainer} ${classes.desktopCowContainer}`
              }
            >
              <Confirmation
                cowState={cowState}
                testType={testType}
                reasonType={reasonType}
                testDate={selectedDate}
              />
            </div>
          </div>
          <div className={classes.footer}>
            <GoBackToFormButton />
            <SendButton />
          </div>
        </>
      )}
    </div>
  );
}

Form.propTypes = {
  testType: PropTypes.number.isRequired,
  reasonType: PropTypes.number.isRequired,
  getResultsFromPostsCallback: PropTypes.func.isRequired,
};
