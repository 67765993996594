import React from 'react';
import {
  makeStyles,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CancelIcon from '@material-ui/icons/Cancel';
import { yyyymmdd } from '../../Services/Utility/DateUtils';
import {
  getActionPackageType,
  getResultType,
  getMeasurementMethod,
  getReason,
} from '../../Services/Utility/MeasurementInformation';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { useTranslation, Trans } from 'react-i18next';
import PermissionCheck from '../../Services/PermissionCheck';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  panel: {
    boxShadow: theme.shadows.small,
    borderRadius: '4px',
  },
  panelSummary: {
    padding: theme.spacing(0, 1),
  },
  panelSummaryContent: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  summaryRow: {
    display: 'flex',
    alignItems: 'center',
    '&>p:first-of-type': {
      marginRight: theme.spacing(1),
    },
    '&>h5:first-of-type': {
      marginRight: theme.spacing(1),
    },
  },
  summarySecondRow: {
    color: theme.palette.grays.gray80,
  },
  panelDetails: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  information: {
    padding: theme.spacing(1),
    background: theme.palette.grays.gray5,
  },
  result: {
    padding: theme.spacing(1),
  },
  informationBody: {
    marginTop: theme.spacing(1),
  },
  testingInfo: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    flexWrap: 'wrap',
    '&>div:not(:last-child)': {
      marginRight: theme.spacing(0.75),
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5),
    '& svg': {
      marginRight: theme.spacing(0.3),
    },
  },
  removeButton: {
    background: '#FFFFFF',
  },
  noteWrapper: {
    marginBottom: theme.spacing(1),
    '&>p': {
      wordBreak: 'break-word',
    },
  },
  resultIcon: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  suspectedKetosisColor: {
    color: theme.palette.complementColors.blueGrey,
  },
  newTestLightColor: {
    background: theme.palette.complementColors.blueGrey80,
  },
  earlyKetosisColor: {
    color: theme.palette.complementColors.darkRed,
  },
  earlyKetosisLightColor: {
    background: theme.palette.complementColors.lightRed40,
  },
  treatmentLateKetosisColor: {
    color: theme.palette.complementColors.darkYellow,
  },
  treatmentLateKetosisLightColor: {
    background: theme.palette.complementColors.lightYellow10,
  },
  noActionLightColor: {
    background: theme.palette.complementColors.lightGreen10,
  },
  noActionColor: {
    color: theme.palette.complementColors.green,
  },
  expandIcon: {
    color: theme.palette.grays.gray100,
  },
  cancelMeasurementColor: {
    color: theme.palette.grays.gray40,
  },
}));

export default function MeasurementCard(props) {
  const { measurement, actionPackage, openDeleteDialog } = props;
  const { t } = useTranslation(['measurements', 'actionPackage']);
  const classes = useStyles();
  const canDeleteMeasurements = PermissionCheck.canDeleteMeasurements();
  const resultType = actionPackage ? getResultType(actionPackage.actionPackageId) : null;
  const actionPackageType = actionPackage
    ? getActionPackageType(actionPackage.actionPackageId)
    : null;
  const measurementIsCancelled = measurement.measurementResult === 5;

  function getResultColor() {
    if (resultType === 'treatment' && actionPackageType === 'earlyKetosis') {
      return classes.earlyKetosisLightColor;
    } else if (resultType === 'treatment' && actionPackageType !== 'earlyKetosis') {
      return classes.treatmentLateKetosisLightColor;
    } else if (resultType === 'newTest') {
      return classes.newTestLightColor;
    } else if (resultType === 'noAction') {
      return classes.noActionLightColor;
    }
  }

  const Summary = () => {
    return (
      <div>
        <div className={classes.summaryRow}>
          <Typography variant='h5'>{measurement.subject.name}</Typography>
          <Typography>{yyyymmdd(new Date(measurement.measuredAt))}</Typography>
        </div>
        <div className={`${classes.summaryRow} ${classes.summarySecondRow}`}>
          {measurementIsCancelled ? (
            <Typography>{t('canceled')}</Typography>
          ) : (
            <>
              <Typography>{`${t('actionPackage:DIM')}: ${measurement.daysInMilk}`}</Typography>
              <Typography>{`BHB: ${measurement.bhbValue}`}</Typography>
            </>
          )}
        </div>
      </div>
    );
  };

  const Information = () => {
    return (
      <div className={classes.information}>
        <Typography variant='h5'>{t('information')}</Typography>
        <div className={classes.informationBody}>
          <div className={classes.testingInfo}>
            <div className={classes.info}>
              <CheckCircleOutlineIcon fontSize='small' />
              <Typography variant='body2'>
                {getMeasurementMethod(measurement.measurementMethod)}
              </Typography>
            </div>
            {measurement.reasons.length > 0 &&
              measurement.reasons.map((reason) => (
                <div className={classes.info} key={reason}>
                  <CheckCircleOutlineIcon fontSize='small' />
                  <Typography variant='body2'>{getReason(reason)}</Typography>
                </div>
              ))}
          </div>

          {measurement.note && (
            <div className={classes.noteWrapper}>
              <Typography variant='h6' gutterBottom>
                {`${t('notes')}:`}
              </Typography>
              <Typography variant='body2'>{measurement.note}</Typography>
            </div>
          )}

          {canDeleteMeasurements && (
            <Button
              className={classes.removeButton}
              startIcon={<DeleteOutlineIcon />}
              variant='outlined'
              size='small'
              onClick={() => openDeleteDialog(measurement.measurementId)}
            >
              {t('deleteTest')}
            </Button>
          )}
        </div>
      </div>
    );
  };

  const Result = () => {
    return (
      <div className={`${classes.result} ${getResultColor()}`}>
        <Typography variant='h5' gutterBottom>
          {t(`actionPackage:type:${actionPackageType}`)}
        </Typography>
        <Typography>
          <Trans i18nKey={`actionPackage:${actionPackage.name}`} />
        </Typography>
      </div>
    );
  };

  const ResultIcon = () => {
    if (resultType === 'cancelTreatment') {
      return <CancelOutlinedIcon className={classes.resultIcon} />;
    } else if (resultType === 'newTest') {
      return (
        <HelpOutlinedIcon className={`${classes.resultIcon} ${classes.suspectedKetosisColor}`} />
      );
    } else if (resultType === 'treatment' && actionPackageType === 'earlyKetosis') {
      return (
        <WarningOutlinedIcon className={`${classes.resultIcon} ${classes.earlyKetosisColor}`} />
      );
    } else if (resultType === 'treatment' && actionPackageType !== 'earlyKetosis') {
      return (
        <WarningOutlinedIcon
          className={`${classes.resultIcon} ${classes.treatmentLateKetosisColor}`}
        />
      );
    } else if (resultType === 'noAction') {
      return (
        <CheckCircleOutlineIcon className={`${classes.resultIcon} ${classes.noActionColor}`} />
      );
    } else if (measurementIsCancelled) {
      return <CancelIcon className={`${classes.resultIcon} ${classes.cancelMeasurementColor}`} />;
    } else {
      return <div style={{ width: '3rem' }}></div>;
    }
  };

  const CancelledMeasurementInformation = () => {
    return (
      <div className={classes.information}>
        {canDeleteMeasurements && (
          <Button
            className={classes.removeButton}
            startIcon={<DeleteOutlineIcon />}
            variant='outlined'
            size='small'
            onClick={() => openDeleteDialog(measurement.measurementId)}
          >
            {t('deleteTest')}
          </Button>
        )}
      </div>
    );
  };

  return (
    <Accordion classes={{ root: classes.panel }}>
      <AccordionSummary
        classes={{ root: classes.panelSummary, content: classes.panelSummaryContent }}
        expandIcon={<ExpandMoreIcon fontSize='large' className={classes.expandIcon} />}
      >
        <ResultIcon />
        <Summary />
      </AccordionSummary>
      <AccordionDetails classes={{ root: classes.panelDetails }}>
        {measurementIsCancelled ? (
          <CancelledMeasurementInformation />
        ) : (
          <>
            <Information />
            {actionPackageType && <Result />}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

MeasurementCard.propTypes = {
  measurement: PropTypes.object.isRequired,
  actionPackage: PropTypes.object,
  openDeleteDialog: PropTypes.func.isRequired,
};
