import React from 'react';
import { makeStyles, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import Action from './Action';
import { useTranslation } from 'react-i18next';
import { enums } from '../../../Services/Enumerations';

const useStyles = makeStyles((theme) => ({
  actionType: {
    marginBottom: theme.spacing(2),
  },
  testInfo: {
    boxSizing: 'border-box',
    width: '100%',
    boxShadow: theme.shadows.small,
    background: theme.palette.complementColors.lightYellow,
    padding: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
}));

export default function ActionGroup(props) {
  const { type, openDialog, actions, activePage, refreshTodos } = props;
  const classes = useStyles();
  const { t } = useTranslation('to-do');

  const getActionTypeTitle = () => {
    let text = '';

    switch (type) {
      case enums.actionType.counselings:
        text = t('cancelTreatment');
        break;
      case enums.actionType.treatments:
        text = t('treat');
        break;
      case enums.actionType.tests:
        text = t('takeNewSample');
        break;
      default:
        break;
    }

    return <Typography variant='h4'>{text}</Typography>;
  };

  const TestInfo = () => {
    return (
      <Paper elevation={0} className={classes.testInfo}>
        <Typography>{t('testAgainInfoBox')}</Typography>
      </Paper>
    );
  };

  if (actions?.length > 0) {
    return (
      <div className={classes.actionType}>
        {getActionTypeTitle()}
        {type === 'tests' && <TestInfo />}
        {actions.map((action, index) => {
          return (
            <Action
              key={index}
              type={type}
              openDialog={openDialog}
              action={action.action}
              cows={action.subjectGroupedActionPlans}
              activePage={activePage}
              refreshTodos={refreshTodos}
            />
          );
        })}
      </div>
    );
  } else {
    return <></>;
  }
}

ActionGroup.propTypes = {
  type: PropTypes.string.isRequired,
  openDialog: PropTypes.func.isRequired,
  actions: PropTypes.array,
  activePage: PropTypes.instanceOf(Date).isRequired,
  refreshTodos: PropTypes.func.isRequired,
};
