import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const UserContext = createContext({
  userSite: '',
  userName: '',
  userId: '',
  canRegister: false,
  selectedSite: null,
  setSelectedSite: () => {},
  sites: [],
  setSites: () => {},
});

export const UserProvider = (props) => {
  const { children, userSiteId, userName, userId } = props;
  const [selectedSite, setSelectedSite] = useState(null);
  const [sites, setSites] = useState([]);

  const userSite = userSiteId && userSiteId !== '' && userSiteId !== 'null' ? userSiteId : null;

  const canRegister = userSite !== null;

  return (
    <UserContext.Provider
      value={{
        userSite,
        userName,
        userId,
        canRegister,
        selectedSite,
        setSelectedSite,
        sites,
        setSites,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.object.isRequired,
  userSiteId: PropTypes.string,
  userName: PropTypes.string,
  userId: PropTypes.string,
};
