import React, { useState } from 'react';
import sweden from '../../assets/swedenicon-32.png';
import uk from '../../assets/united-kingdom-icon-32.png';
import france from '../../assets/france.png';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { useTranslation } from 'react-i18next';
import { FormControl, Select, MenuItem, Input, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  formControl: {
    '& svg': {
      color: 'white',
    },
  },
  select: {
    background: 'transparent',
    '&:focus': {
      background: 'transparent',
    },
  },
  menuItemLanguage: {
    width: '12.5rem',
    minHeight: '3rem',
  },
  menuFlag: {
    marginRight: '2rem',
  },
});

export default function SelectLanguageFlag() {
  const { i18n } = useTranslation();
  const classes = useStyles();

  const [language, setLanguage] = useState(i18n.language ? i18n.language.slice(0, 2) : 'en');

  function changeLanguage(lng) {
    setLanguage(lng);
    i18n.changeLanguage(lng);
  }

  function getFlag() {
    if (language === 'sv') {
      return sweden;
    } else if (language === 'fr') {
      return france;
    } else {
      return uk;
    }
  }

  return (
    <FormControl classes={{ root: classes.formControl }}>
      <Select
        value={language}
        onChange={(e) => changeLanguage(e.target.value)}
        name='change language'
        renderValue={() => <img src={getFlag()} alt='flag' width='20px' height='20px'></img>}
        input={<Input disableUnderline={true} name='name' />}
        IconComponent={KeyboardArrowDown}
        classes={{
          select: classes.select,
        }}
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <MenuItem value='sv' className={classes.menuItemLanguage}>
          <img
            className={classes.menuFlag}
            src={sweden}
            alt='flag'
            width='20px'
            height='20px'
          ></img>
          <Typography variant='body1' align='center'>
            Svenska
          </Typography>
        </MenuItem>
        <MenuItem value='en' className={classes.menuItemLanguage}>
          <img className={classes.menuFlag} src={uk} alt='flag' width='20px' height='20px' />
          <Typography variant='body1' align='center'>
            English
          </Typography>
        </MenuItem>
        <MenuItem value='fr' className={classes.menuItemLanguage}>
          <img className={classes.menuFlag} src={france} alt='flag' width='20px' height='20px' />
          <Typography variant='body1' align='center'>
            Français
          </Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
}
