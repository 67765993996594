import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles, Typography, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { yyyymmdd } from '../../Services/Utility/DateUtils';
import { isToday, isYesterday, sub, isSameDay, isTomorrow } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ToDoInfoCard from './ToDoInfoCard';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
    '& button': {
      color: theme.palette.grays.gray100,
    },
  },
  date: {
    minWidth: theme.spacing(8),
    textAlign: 'center',
  },
  newDayCard: {
    boxSizing: 'border-box',
    boxShadow: theme.shadows.small,
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.complementColors.lightYellow,
    display: 'flex',
    '& span': {
      marginRight: theme.spacing(1),
      fontSize: 'x-large',
    },
  },
  link: {
    cursor: 'pointer',
  },
  bump: {
    height: '48px',
    width: '48px',
  },
}));

export default function ToDoHeader(props) {
  const { onPageUpdated, isLoadingPage, isCurrentPageEmpty } = props;
  const classes = useStyles();
  const { t } = useTranslation('to-do');
  const now = new Date();

  const [activePage, setActivePage] = useState(now);

  const changeDay = useCallback((date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }, []);

  const getDateTitle = useCallback(() => {
    if (isToday(activePage)) {
      return t('today');
    } else if (isYesterday(activePage)) {
      return t('yesterday');
    } else if (isTomorrow(activePage)) {
      return t('tomorrow');
    } else {
      return yyyymmdd(activePage);
    }
  }, [activePage, t]);

  useEffect(() => {
    onPageUpdated(activePage);
  }, [activePage, onPageUpdated]);

  return (
    <>
      <div className={classes.headerWrapper}>
        {isSameDay(activePage, sub(now, { weeks: 1 })) ? (
          <div className={classes.bump}></div>
        ) : (
          <IconButton
            onClick={() => setActivePage((prevPage) => changeDay(prevPage, -1))}
            disabled={isLoadingPage}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant='subtitle1' className={classes.date}>
          {getDateTitle()}
        </Typography>
        {!isTomorrow(activePage) ? (
          <IconButton
            onClick={() => setActivePage((prevPage) => changeDay(prevPage, 1))}
            disabled={isLoadingPage}
          >
            <ArrowForwardIcon />
          </IconButton>
        ) : (
          <div className={classes.bump}></div>
        )}
      </div>
      {!isLoadingPage && !isToday(activePage) && (
        <ToDoInfoCard
          onClickSeeTodaysTasks={() => setActivePage(now)}
          activePage={activePage}
          isCurrentPageEmpty={isCurrentPageEmpty}
        />
      )}
    </>
  );
}

ToDoHeader.propTypes = {
  onPageUpdated: PropTypes.func.isRequired,
  isLoadingPage: PropTypes.bool.isRequired,
  isCurrentPageEmpty: PropTypes.bool.isRequired,
};
