import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const HeaderContext = createContext({
  showQuestionmark: false,
  setShowQuestionmark: () => {},
  showInfoBox: false,
  setShowInfoBox: () => {},
  headerTitle: '',
  setHeaderTitle: () => {},
});

export const HeaderProvider = (props) => {
  const [showQuestionmark, setShowQuestionmark] = useState(false);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [headerTitle, setHeaderTitle] = useState('');

  return (
    <HeaderContext.Provider
      value={{
        showQuestionmark,
        setShowQuestionmark,
        showInfoBox,
        setShowInfoBox,
        headerTitle,
        setHeaderTitle,
      }}
    >
      {props.children}
    </HeaderContext.Provider>
  );
};

HeaderProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
