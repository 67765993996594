import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography } from '@material-ui/core';
import ActionPackage from './ActionPackage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0.8),
    '&>svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

export default function ResultGroup(props) {
  const { type, resultGroup } = props;
  const classes = useStyles();
  const { t } = useTranslation('result');

  return (
    <div>
      <div className={classes.headerWrapper}>
        <Typography variant='h4'>{t(type)}</Typography>
      </div>
      {resultGroup.map((actionPackage) => (
        <ActionPackage
          key={actionPackage.actionPackage.actionPackageId}
          resultType={type}
          actionPackage={actionPackage.actionPackage}
          measurements={actionPackage.measurements}
        />
      ))}
    </div>
  );
}

ResultGroup.propTypes = {
  type: PropTypes.string.isRequired,
  resultGroup: PropTypes.array,
};
