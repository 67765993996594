import React, { useState, useContext } from 'react';
import { makeStyles, Dialog, Button, Typography, CircularProgress } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import DataService from '../../Services/DataService';

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(1.5),
    maxWidth: theme.spacing(20),
    boxSizing: 'border-box',
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    '& svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  buttonContainer: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'space-between',
    '& button': {
      width: theme.spacing(8.75),
      height: theme.spacing(3.25),
    },
    '&>button+button': {
      marginLeft: theme.spacing(1),
    },
  },
  spinner: {
    color: 'white',
    height: '20px !important',
    width: '20px !important',
  },
}));

export default function DeleteMeasurementDialog(props) {
  const { closeDialog, measurement, onDeleteMeasurement } = props;
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();
  const { t } = useTranslation('measurements');
  const [isLoading, setIsLoading] = useState(false);

  // Delete measurement and refresh list of measurements
  function handleClickDelete() {
    deleteMeasurement(measurement);
  }

  async function deleteMeasurement(id) {
    setIsLoading(true);
    const response = await DataService.axiosDelete('Measurement/DeleteMeasurement', id);

    if (response instanceof Error) {
      setSnackbar({ text: 'deleteError', severity: 'warning', open: true });
      setIsLoading(false);
    } else {
      onDeleteMeasurement(id);
      setIsLoading(false);
      closeDialog();
    }
  }

  return (
    <Dialog open={true} onClose={() => closeDialog()}>
      <div className={classes.dialog}>
        <div className={classes.dialogHeader}>
          <DeleteOutlineIcon />
          <Typography variant='subtitle1'>{t('deleteThisTest')}</Typography>
        </div>
        <Typography>{t('deleteAreYouSure')}</Typography>
        <div className={classes.buttonContainer}>
          <Button
            size='large'
            variant='outlined'
            color='primary'
            onClick={() => closeDialog()}
            disableElevation
          >
            {t('cancel')}
          </Button>
          <Button
            size='large'
            variant='contained'
            color='secondary'
            onClick={() => handleClickDelete()}
            disableElevation
            endIcon={isLoading ? <CircularProgress className={classes.spinner} /> : ''}
          >
            {t('delete')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

DeleteMeasurementDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  measurement: PropTypes.string.isRequired,
  onDeleteMeasurement: PropTypes.func.isRequired,
};
