import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LocizeBackend from 'i18next-locize-backend';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import packageJson from '../package.json';

const isProduction = process.env.NODE_ENV === 'production';

const locizeOptionsPrimary = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID_PRIMARY,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY_PRIMARY, // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

const locizeOptionsSecondary = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID_SECONDARY,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY_SECONDARY, // The API key should only be used in development, not in production. You should not =expose your apps API key to production!!!
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

i18n
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      fallbackLng: 'en',
      // Don't load namespaces up front, expect each component to load the proper namespaces
      // ns: ['translations', 'adminView'],
      // Don't assume default namespace, expect each component to load the proper namespaces
      // defaultNS: 'translations',
      debug: !isProduction,
      keySeparator: ':',
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      backend: {
        backends: [LocizeBackend, LocizeBackend, HttpBackend],
        backendOptions: [
          locizeOptionsSecondary,
          locizeOptionsPrimary,
          { loadPath: `/locales/{{lng}}/{{ns}}.json?${packageJson.version}` },
        ],
      },
    },
    (err) => {
      if (err) return console.log('something went wrong loading', err);
      return 'error';
    },
  );

export default i18n;
