import React from 'react';
import { differenceInCalendarDays } from 'date-fns';

export function leftZeroPad(num, targetLength) {
  return num.toString().padStart(targetLength, 0);
}

export function formatCowNameBoldAnimalNumber(cow) {
  return (
    <>
      {cow.prefix}-{leftZeroPad(cow.birthPlaceId, 6)}-
      <strong>{leftZeroPad(cow.animalNumber, 4)}</strong>-{cow.checkSum}
    </>
  );
}

export function getDigit(number, n) {
  return Math.floor((number / Math.pow(10, n - 1)) % 10);
}

export function getCheckSum(birthPlaceId, animalNumber) {
  if (birthPlaceId > 0 && birthPlaceId < 10000000 && animalNumber > 0 && animalNumber < 10000) {
    const odd = [0, 2, 4, 6, 8, 1, 3, 5, 7, 9];
    const sum =
      getDigit(birthPlaceId, 6) +
      odd[getDigit(birthPlaceId, 5)] +
      getDigit(birthPlaceId, 4) +
      odd[getDigit(birthPlaceId, 3)] +
      getDigit(birthPlaceId, 2) +
      odd[getDigit(birthPlaceId, 1)] +
      getDigit(animalNumber, 4) +
      odd[getDigit(animalNumber, 3)] +
      getDigit(animalNumber, 2) +
      odd[getDigit(animalNumber, 1)];

    return (10 - ((sum + 5) % 10)) % 10;
  }
  return null;
}

export function getDIM(lastCalvingDate, compareDate = new Date()) {
  const DIM = differenceInCalendarDays(compareDate, lastCalvingDate);

  if (DIM > 200) {
    return '';
  } else {
    return DIM.toString();
  }
}
