import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const MenuContext = createContext({
  menuActive: false,
  setMenuActive: () => {},
});

export const MenuProvider = (props) => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <MenuContext.Provider value={{ menuActive, setMenuActive }}>
      {props.children}
    </MenuContext.Provider>
  );
};

MenuProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
