import React, { useContext } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';
import PropTypes from 'prop-types';
import DataService from '../../../../Services/DataService';
import { SnackbarContext } from '../../../../Contexts/SnackbarContext';
import { useTranslation, Trans } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: 'calc(100% - 24px)',
    maxWidth: theme.spacing(25),
  },
  errorButton: {
    color: red['500'],
    borderColor: red['500'],
  },
}));

export default function CancelActionDialog(props) {
  const { isOpen, closeDialog, cow, refreshTodos } = props;
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();
  const { t } = useTranslation('to-do');

  async function cancelAction(subjectId) {
    const response = await DataService.putObject(
      'Measurement/CancelActionPlanForSubject',
      {},
      subjectId,
    );
    if (response instanceof Error) {
      setSnackbar({ text: 'cancelActionError', severity: 'warning', open: true });
    } else {
      // Refresh Todos after canceling a treatment
      refreshTodos();
      closeDialog();
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      color='primary'
      aria-labelledby='form-dialog-title'
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id='form-dialog-title' disableTypography>
        <Typography variant='h4'>{t('cancelTreatment')}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>
          <Trans i18nKey={t('cancelConfirmation', { cow: cow.subject.name })} />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={() => closeDialog()} variant='outlined' size='small'>
          {t('undo')}
        </Button>
        <Button
          classes={{ root: classes.errorButton }}
          variant='outlined'
          onClick={() => cancelAction(cow.subject.subjectId)}
          size='small'
        >
          {t('cancelTreatment')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CancelActionDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  cow: PropTypes.object.isRequired,
  refreshTodos: PropTypes.func.isRequired,
};
