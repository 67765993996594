import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import blackWhiteCow from '../../assets/ko-holstein.png';
import brownCow from '../../assets/ko-srb.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  emptyStateWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
    '&>img': {
      marginBottom: theme.spacing(1),
    },
    '&>a': {
      marginTop: '1.5rem',
    },
  },
}));

export default function EmptyState() {
  const classes = useStyles();
  const { t } = useTranslation('measurements');
  const [cow, setCow] = useState(null);

  useEffect(() => {
    // Randomize which one of two cows should be displayed
    if (Math.floor(Math.random() * 2) + 1 < 2) {
      setCow(brownCow);
    } else {
      setCow(blackWhiteCow);
    }
  }, []);

  return (
    <div className={classes.emptyStateWrapper}>
      <img src={cow} alt='Cow' width={200}></img>
      <Typography>{t('noCows')}</Typography>
    </div>
  );
}
