import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  infoItem: {
    marginBottom: theme.spacing(2),
  },
}));

export default function FormInfoContent() {
  const classes = useStyles();
  const { t } = useTranslation('reasonInfoBox');

  return (
    <>
      <div className={classes.infoItem}>
        <Typography variant='h5' gutterBottom>
          {t('strategic')}
        </Typography>
        <Typography>
          <Trans i18nKey={'reasonInfoBox:strategicText'} />
        </Typography>
      </div>
      <div>
        <Typography variant='h5' gutterBottom>
          {t('symptom')}
        </Typography>
        <Typography>{t('symptomText')}</Typography>
      </div>
    </>
  );
}
