import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  searchIconCircle: {
    backgroundColor: theme.palette.grays.gray30,
    width: theme.spacing(6.25),
    height: theme.spacing(6.25),
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    '& svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  noResultsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(1.5),
  },
}));

export default function CowNotFound() {
  const classes = useStyles();
  const { t } = useTranslation('measurements');

  const Search = () => {
    return (
      <div className={classes.searchIconCircle}>
        <SearchIcon />
      </div>
    );
  };

  return (
    <div className={classes.noResultsWrapper}>
      <Search />
      <Typography variant='h4' gutterBottom>
        {t('noResults')}
      </Typography>
      <Typography>{t('tryAdjustSearch')}</Typography>
    </div>
  );
}
