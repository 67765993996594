import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Switch } from 'react-router-dom';
import { PrivateRoute } from '../../Services/Routing';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import { HeaderProvider } from '../../Contexts/HeaderContext';
import { MenuContext } from '../../Contexts/MenuContext';
import { UserContext } from '../../Contexts/UserContext';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import Start from '../Start';
import Menu from '../Menu';
import Header from '../Header';
import DataService from '../../Services/DataService';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
  },
  body: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  content: {
    position: 'absolute',
    width: '80%',
    height: '100%',
    right: '0',
  },
  mobileContent: {
    position: 'absolute',
    width: '100%',
    right: '0',
    height: '100%',
  },
  menuWrapper: {
    position: 'absolute',
    width: '20%',
    height: '100%',
    top: '0',
    left: '0',
    transition: 'height 400ms ease-out',
    backgroundColor: '#fff',
    zIndex: '10',
  },
  mobileMenuWrapper: {
    position: 'absolute',
    width: 'auto',
    height: '100%',
    overflow: 'auto',
    top: '0',
    left: '0',
    transition: 'height 400ms ease-out',
    backgroundColor: '#fff',
    zIndex: '10',
  },
}));

export default function Main(props) {
  const { signedInState, onSignOutCallback } = props;
  const { menuActive, setMenuActive } = useContext(MenuContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { setSelectedSite, setSites, sites, userId, canRegister } = useContext(UserContext);
  const isMobile = useMediaQuery('(max-width:1160px)');
  const classes = useStyles();

  const toggleMenu = () => {
    if (isMobile) {
      setMenuActive((prev) => !prev);
    }
  };

  const onContentPressed = () => {
    if (isMobile && menuActive) {
      setMenuActive(false);
    }
  };

  useEffect(() => {
    const fetchSites = async () => {
      const siteResponse = await DataService.fetchData('Site/GetSitesForUser');
      if (!siteResponse || siteResponse instanceof Error) {
        setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
        setSites([]);
      } else {
        setSites(siteResponse);
      }
    };

    try {
      // we only need sites if the user is not a site-user
      if (userId && !canRegister) {
        fetchSites();
      }
    } catch (error) {
      console.log(error);
      setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
    }
  }, [userId, setSnackbar, setSites, canRegister]);

  // select a site as default
  useEffect(() => {
    if (sites.length) {
      setSelectedSite(sites[0]);
    }
  }, [sites, setSelectedSite]);

  useEffect(() => {
    setMenuActive(!isMobile);
  }, [isMobile, setMenuActive]);

  const pathArray = window.location.pathname.split('/');

  // If path is "/cost", don't display any header or menu components.
  // This path is used to host the HubSpot form displayed on the Agricam homepage.
  if (window.location.pathname === '/cost') {
    return <></>;
    // below check is done to see if we are navigated to '/'
    // we only want to redirect to login from render in that case
    // the other redirects are handled by the PrivateRoute
  } else if (pathArray.length === 2 && signedInState === 'signedOut') {
    return <Redirect to='/login' />;
  }

  return (
    <HeaderProvider>
      <div className={classes.container}>
        <Header toggleMenu={toggleMenu} onClick={onContentPressed} />
        <div className={classes.body}>
          <div
            className={isMobile ? classes.mobileContent : classes.content}
            onClick={onContentPressed}
          >
            <Switch>
              <PrivateRoute path='/' signedInState={signedInState}>
                <Start />
              </PrivateRoute>
            </Switch>
          </div>
          {menuActive ? (
            <div className={isMobile ? classes.mobileMenuWrapper : classes.menuWrapper}>
              <Menu onSignOut={onSignOutCallback} />
            </div>
          ) : (
            <> </>
          )}
        </div>
      </div>
    </HeaderProvider>
  );
}

Main.propTypes = {
  signedInState: PropTypes.string.isRequired,
  onSignOutCallback: PropTypes.func.isRequired,
};
