import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DataService from '../../Services/DataService';
import { SnackbarContext } from '../../Contexts/SnackbarContext';

import logo from './agricamlogga.svg';
import {
  List,
  ListItem,
  Button,
  Divider,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  makeStyles,
  Link,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import CopyrightIcon from '@material-ui/icons/Copyright';
import HelpIcon from '@material-ui/icons/Help';
import Header from '../Header';
import { UserManager } from '../../Services/UserManager';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: '#F4F4F4',
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  loginContainer: {
    maxWidth: '420px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    borderRadius: '4px',
    textAlign: 'center',
    boxShadow: '0 0 1.25rem rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '0 32px 16px',
    boxSizing: 'border-box',
  },
  loginContainerMobile: {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignSelf: 'center',
    backgroundColor: '#fff',
    padding: '0 32px 16px',
    boxSizing: 'border-box',
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '420px',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  loginLogo: {
    height: '100px',
  },
  loginForm: {
    background: '#fff',
    paddingBottom: '24px',
  },
  group: {
    position: 'relative',
    marginBottom: '16px',
  },
  loginFailedWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: '2%',
  },
  loginNotFailed: {
    display: 'none',
  },
  loadingWrapper: {
    marginTop: '2%',
  },
  supportContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '420px',
  },
  supportList: {
    width: '100%',
  },
  supportListItemWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  forgotPasswordWrapper: {
    cursor: 'pointer',
  },
  supportListItemText: {
    marginLeft: '2%',
  },
  iconWrapper: {
    marginRight: '0.25rem',
  },
  dialogTextField: {
    marginTop: '0.3rem',
  },
  divider: {
    margin: '0 -32px',
  },
  dialogTitle: {
    padding: theme.spacing(1),
  },
  copyrightWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Login(props) {
  const {
    successfulSignInCallback,
    onPendingSignInCallback,
    failedSignInCallback,
    forbiddenSignInCallback,
    signedInState,
  } = props;
  const { t } = useTranslation('loginView');
  const isSmallMobile = useMediaQuery('(max-width:500px)');
  const classes = useStyles();
  const now = new Date();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetPasswordOpen, setResetPasswordOpen] = useState(false);
  const { setSnackbar } = useContext(SnackbarContext);

  let loginFailed = false;
  let errorText = '';
  // Credentials check

  useEffect(() => {
    document.title = `${t('Login')} | KetosKoll`;
  }, [t]);

  // TODO figure out how to see if we came from a specific route and redirect to that one
  // instead of only samples
  switch (signedInState) {
    case 'signedIn':
      return <Redirect to='/' />;
    case 'failed':
      loginFailed = true;
      errorText = t('loginFailed');
      break;
    case 'forbidden':
      loginFailed = true;
      errorText = t('loginForbidden');
      break;
    default:
      break;
  }

  async function login(event) {
    onPendingSignInCallback();
    event.preventDefault();
    (async () => {
      try {
        const loginObject = {
          mail: email,
          password: password,
        };
        UserManager.removeUser();
        const userResponse = await DataService.postObject('Account/Login', loginObject).then(
          (response) => {
            if (response === undefined) {
              // did not get a response, server probably down
              return null;
            }
            return response;
          },
        );
        if (userResponse instanceof Error) {
          switch (userResponse.response.status) {
            case 401:
              failedSignInCallback();
              break;
            case 403:
              // user response returned 'forbidden' code
              forbiddenSignInCallback();
              break;
            default:
              failedSignInCallback();
              setSnackbar({ text: t('somethingWentWrong'), severity: 'warning', open: true });
              break;
          }
        } else if (userResponse) {
          successfulSignInCallback(userResponse);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  function handleResetPassword() {
    if (
      document.getElementById('password-input').value === undefined ||
      document.getElementById('password-input').value === ''
    ) {
      return;
    }

    (async () => {
      try {
        const mail = {
          mail: document.getElementById('password-input').value,
        };

        const passwordResponse = await DataService.postObject('Account/ResetPassword', mail).then(
          (response) => {
            if (response === undefined) {
              // did not get a response, server probably down
              return null;
            }
            return response;
          },
        );

        if (passwordResponse instanceof Error) {
          switch (passwordResponse.response.status) {
            case 500:
            case 429:
              setSnackbar({ text: t('resetFailed'), severity: 'warning', open: true });
              break;
            case 400:
              setSnackbar({ text: t('wrongFormat'), severity: 'warning', open: true });

              break;
            default:
              setSnackbar({ text: t('somethingWentWrong'), severity: 'warning', open: true });

              break;
          }
        } else {
          setSnackbar({ text: t('passwordReset'), severity: 'success', open: true });
          setResetPasswordOpen(false);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  return (
    <div className={classes.wrapper}>
      <Header />
      <main className={classes.main}>
        <div className={isSmallMobile ? classes.loginContainerMobile : classes.loginContainer}>
          <div className={classes.logoContainer}>
            <img className={classes.loginLogo} src={logo} alt={'Agricam'} />
          </div>
          <Divider className={classes.divider} />
          <form className={classes.loginForm} onSubmit={login}>
            <div>
              <div className={classes.group}>
                <TextField
                  id='standard-email-input'
                  label={t('Email')}
                  type='email'
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                  margin='normal'
                />
              </div>
              <div className={classes.group}>
                <TextField
                  id='standard-password-input'
                  label={t('Password')}
                  type='password'
                  autoComplete='current-password'
                  margin='normal'
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                />
              </div>
              <div
                className={
                  loginFailed
                    ? classes.loginFailedWrapper
                    : `${classes.loginFailedWrapper} ${classes.loginNotFailed}`
                }
              >
                <ErrorIcon color={'error'} className={classes.iconWrapper} />
                <Typography color={'error'}>{errorText}</Typography>
              </div>
            </div>
            {signedInState === 'pending' ? (
              <CircularProgress className={classes.loadingWrapper} />
            ) : (
              <Button type='submit' variant='contained' color='primary' fullWidth size='small'>
                {t('Login')}
              </Button>
            )}
          </form>
          <Divider className={classes.divider} />
          <div className={classes.supportContainer}>
            <List className={classes.supportList}>
              <ListItem disableGutters>
                <Typography color='primary' variant='subtitle1'>
                  Support
                </Typography>
              </ListItem>
              <ListItem disableGutters onClick={() => setResetPasswordOpen(true)}>
                <div
                  className={`${classes.supportListItemWrapper} ${classes.forgotPasswordWrapper}`}
                >
                  <HelpIcon color='primary' />
                  <Typography color='primary' className={classes.supportListItemText}>
                    {t('forgotPassword')}
                  </Typography>
                </div>
              </ListItem>
              <ListItem
                disableGutters
                component='a'
                key='Email'
                href='https://knowledge.agricam.se/knowledge/kb-tickets/new'
                target='_blank'
                rel='noreferrer'
              >
                <div className={classes.supportListItemWrapper}>
                  <MailIcon color='primary' />
                  <Typography color='primary' className={classes.supportListItemText}>
                    {t('contactSupport')}
                  </Typography>
                </div>
              </ListItem>
              <ListItem disableGutters component='a' key='Phone' href='tel:+46768619908'>
                <div className={classes.supportListItemWrapper}>
                  <PhoneIcon color='primary' />
                  <Typography color='primary' className={classes.supportListItemText}>
                    +46768619908
                  </Typography>
                </div>
              </ListItem>
            </List>
          </div>
          <Typography variant='caption' color='primary'>
            Version: {process.env.REACT_APP_VERSION}
          </Typography>
          <Link href='/oss-licenses.json' variant='caption' color='primary'>
            {t('ossLicenses')}
          </Link>
          <div className={classes.copyrightWrapper}>
            <CopyrightIcon color='primary' fontSize='inherit' className={classes.iconWrapper} />
            <Typography variant='caption' color='primary'>
              {`${now.getFullYear()} Agricam AB`}
            </Typography>
          </div>

          {resetPasswordOpen && (
            <Dialog
              open={resetPasswordOpen}
              keepMounted
              onClose={() => setResetPasswordOpen(false)}
              aria-labelledby='alert-dialog-slide-title'
              aria-describedby='alert-dialog-slide-description'
            >
              <DialogTitle id='form-dialog-title'>{t('resetPassword')}</DialogTitle>
              <DialogContent>
                <DialogContentText>{t('resetPasswordSubtitle')}</DialogContentText>
                <TextField
                  id='password-input'
                  label={t('Email')}
                  variant='outlined'
                  fullWidth
                  className={classes.dialogTextField}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setResetPasswordOpen(false)} color='primary'>
                  {t('cancel')}
                </Button>
                <Button onClick={handleResetPassword} color='primary'>
                  {t('send')}
                </Button>
              </DialogActions>
            </Dialog>
          )}
        </div>
      </main>
    </div>
  );
}

Login.propTypes = {
  onPendingSignInCallback: PropTypes.func,
  failedSignInCallback: PropTypes.func.isRequired,
  successfulSignInCallback: PropTypes.func.isRequired,
  forbiddenSignInCallback: PropTypes.func.isRequired,
  signedInState: PropTypes.string.isRequired,
};
