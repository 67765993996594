import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { enums } from '../../Services/Enumerations';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  checkBoxesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1),
    '& span': {
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.body2.fontWeight,
      lineHeight: theme.typography.body2.lineHeight,
      letterSpacing: theme.typography.body2.letterSpacing,
    },
  },
  noteButton: {
    padding: '9px 0',
    '& svg': {
      marginRight: theme.spacing(0.5),
      color: theme.palette.primary.main,
    },
    '& p': {
      textAlign: 'start',
    },
  },
}));

export default function CheckBoxes(props) {
  const { onReasonChange, cowData, openNoteDialog, cowIndex } = props;
  const classes = useStyles();
  const { t } = useTranslation('formComponent');
  const cowId = `cowNumber-${cowIndex}`;

  const hasSymptom = cowData.reasons.some((reason) => reason === enums.MeasurementReason.Symptom);

  const hasKnownKetosisReason = cowData.reasons.some(
    (reason) => reason === enums.MeasurementReason.KnownKetosisReason,
  );

  const isFirstTimeCalver = cowData.reasons.some(
    (reason) => reason === enums.MeasurementReason.FirstTimeCalver,
  );

  return (
    <FormControl classes={{ root: classes.checkBoxesWrapper }}>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox color='primary' />}
          label={t('symptom')}
          type='checkbox'
          color='primary'
          onChange={() => onReasonChange(enums.MeasurementReason.Symptom)}
          name='reasons'
          id={cowId}
          data-idx={cowIndex}
          checked={hasSymptom}
        ></FormControlLabel>
        <FormControlLabel
          control={<Checkbox color='primary' />}
          label={t('knownKetosisReason')}
          type='checkbox'
          color='primary'
          onChange={() => onReasonChange(enums.MeasurementReason.KnownKetosisReason)}
          name='reasons'
          id={cowId}
          data-idx={cowIndex}
          checked={hasKnownKetosisReason}
        ></FormControlLabel>
      </FormGroup>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox color='primary' />}
          label={t('firstTimeCalver')}
          type='checkbox'
          color='primary'
          onChange={() => onReasonChange(enums.MeasurementReason.FirstTimeCalver)}
          name='reasons'
          id={cowId}
          data-idx={cowIndex}
          checked={isFirstTimeCalver}
        ></FormControlLabel>
        <Button
          variant='text'
          color='primary'
          className={classes.noteButton}
          onClick={openNoteDialog}
        >
          <EditIcon />
          <Typography variant='body2'>{t('addNote')}</Typography>
        </Button>
      </FormGroup>
    </FormControl>
  );
}

CheckBoxes.propTypes = {
  onReasonChange: PropTypes.func.isRequired,
  openNoteDialog: PropTypes.func.isRequired,
  cowData: PropTypes.object.isRequired,
  cowIndex: PropTypes.number.isRequired,
};
