import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@material-ui/core';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { Link } from 'react-router-dom';
import DropIcon from '@material-ui/icons/InvertColors';
import { enums } from '../../Services/Enumerations';

const useStyles = makeStyles((theme) => ({
  pickMethodContainer: {
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '3rem',
    '& p': {
      textAlign: 'center',
    },
  },
  buttonAndTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.spacing(9),
    height: theme.spacing(12),
  },
  bloodButtonAndTextWrapper: {
    marginRight: theme.spacing(1),
  },
  button: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    marginBottom: theme.spacing(2),
    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  blood: {
    backgroundColor: '#fff',
    '& svg': {
      color: theme.palette.secondary.main,
    },
  },
  milk: {
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '& svg': {
      color: 'white',
    },
  },
}));

export default function TestType(props) {
  const setTestType = props.setTestType;
  const { t } = useTranslation('testTypeComponent');
  const { setHeaderTitle, setShowQuestionmark } = useContext(HeaderContext);
  const classes = useStyles();

  useEffect(() => {
    setHeaderTitle(t('newTest'));
    document.title = `${t('newTest')} | KetosKoll`;
    setShowQuestionmark(false);
  }, [setHeaderTitle, setShowQuestionmark, t]);

  const BloodButton = () => {
    return (
      <div className={`${classes.buttonAndTextWrapper} ${classes.bloodButtonAndTextWrapper}`}>
        <Button
          variant='contained'
          disableElevation
          className={`${classes.button} ${classes.blood}`}
          component={Link}
          to='/reason'
          onClick={() => {
            setTestType(enums.BhbMeasurementMethod.Blood);
          }}
        >
          <DropIcon />
        </Button>
        <Typography color='textPrimary'>{t('bloodTest')}</Typography>
      </div>
    );
  };

  const MilkButton = () => {
    return (
      <div className={classes.buttonAndTextWrapper}>
        <Button
          variant='contained'
          disableElevation
          className={`${classes.button} ${classes.milk}`}
          component={Link}
          to='/reason'
          onClick={() => {
            setTestType(enums.BhbMeasurementMethod.Milk);
          }}
        >
          <DropIcon />
        </Button>
        <Typography color='textPrimary'>{t('milkTest')}</Typography>
      </div>
    );
  };

  return (
    <div className={classes.pickMethodContainer}>
      <Typography variant='h4'>{t('mainTitle')}</Typography>
      <div className={classes.buttonContainer}>
        <BloodButton />
        <MilkButton />
      </div>
    </div>
  );
}

TestType.propTypes = {
  setTestType: PropTypes.func.isRequired,
};
