import React, { useState, useEffect } from 'react';
import { Paper, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckBoxes from '../CowCheckBoxes';
import CowNumberInput from './CowCardCowNumberInput';
import DimInput from './CowCardDimInput';
import BhbInput from './CowCardBhbInput';
import FullIdentity from './CowCardFullCowIdentity';

const useStyles = makeStyles((theme) => ({
  cowCardWrapper: {
    borderRadius: '4px',
    padding: theme.spacing(1),
    boxShadow: theme.shadows.small,
    marginBottom: theme.spacing(1),
  },
  cowNumberAndDeleteIconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '&>svg': {
      marginLeft: theme.spacing(1),
    },
  },
  dimAndBhbWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    gap: '16px',
  },
  deleteButton: {
    color: theme.palette.grays.gray100,
  },
}));

export default function CowCard(props) {
  const {
    index,
    pressedContinueButton,
    cowData,
    updateCowProperty,
    openNoteDialog,
    onDeleteClick,
    toggleCowReason,
  } = props;
  const classes = useStyles();

  const [invalidIdentifier, setInvalidIdentifier] = useState(true);
  const [invalidBHB, setInvalidBhb] = useState(true);
  const [invalidDIM, setInvalidDim] = useState(true);

  // Tests if cow number, DIM and BHB are valid values.
  useEffect(() => {
    setInvalidIdentifier(
      !Number.isInteger(Number.parseFloat(cowData.identifier)) ||
        Math.sign(cowData.identifier) !== 1,
    );
    setInvalidDim(
      !Number.isInteger(Number.parseFloat(cowData.daysInMilk)) || Math.sign(cowData.daysInMilk) < 0,
    );
    setInvalidBhb(
      Number.isNaN(Number.parseFloat(cowData.bhbValue)) ||
        Math.sign(Math.floor(cowData.bhbValue)) < 0,
    );
  }, [cowData.identifier, cowData.daysInMilk, cowData.bhbValue]);

  function onReasonChange(reason) {
    toggleCowReason(index, reason);
  }

  return (
    <Paper className={classes.cowCardWrapper} key={`cat-${index}`} elevation={0}>
      <div className={classes.cowNumberAndDeleteIconWrapper}>
        <CowNumberInput
          index={index}
          cowData={cowData}
          invalid={invalidIdentifier && pressedContinueButton}
          updateCowProperty={updateCowProperty}
          onReasonChange={onReasonChange}
        />
        <IconButton data-idx={index} onClick={onDeleteClick} className={classes.deleteButton}>
          <DeleteOutlineIcon fontSize='large' />
        </IconButton>
      </div>
      {cowData.identifier && (
        <FullIdentity
          index={index}
          updateCowProperty={updateCowProperty}
          cowData={cowData}
          pressedContinueButton={pressedContinueButton}
        />
      )}
      <div className={classes.dimAndBhbWrapper}>
        <DimInput
          index={index}
          value={cowData.daysInMilk}
          invalid={invalidDIM && pressedContinueButton}
          updateCowProperty={updateCowProperty}
        />
        <BhbInput
          index={index}
          value={cowData.bhbValue}
          invalid={invalidBHB && pressedContinueButton}
          updateCowProperty={updateCowProperty}
        />
      </div>
      <CheckBoxes
        cowIndex={index}
        onReasonChange={onReasonChange}
        cowData={cowData}
        openNoteDialog={openNoteDialog}
      />
    </Paper>
  );
}

CowCard.propTypes = {
  index: PropTypes.number.isRequired,
  cowData: PropTypes.object.isRequired,
  pressedContinueButton: PropTypes.bool.isRequired,
  updateCowProperty: PropTypes.func.isRequired,
  openNoteDialog: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  toggleCowReason: PropTypes.func.isRequired,
};
