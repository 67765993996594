import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Fade, Collapse } from '@material-ui/core';
import NoteDialog from './CowNoteDialog';
import CowCard from './CowCard';

export default function Cow(props) {
  const {
    cowIndex,
    cowData,
    deleteCow,
    updateCowProperty,
    toggleCowReason,
    pressedContinueButton,
  } = props;
  const [showCow, setShowCow] = useState(true);
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [noteValue, setNoteValue] = useState(cowData.note);

  const onNoteDialogClose = (saveNote) => {
    if (saveNote) {
      updateCowProperty(cowIndex, 'note', noteValue);
    } else {
      setNoteValue(cowData.note);
    }
    setNoteDialogOpen(false);
  };

  return (
    <Fade
      in={showCow}
      onExited={() => {
        deleteCow(cowIndex);
        setShowCow(true);
      }}
      {...(showCow ? { timeout: 0 } : { timeout: 500 })}
    >
      <div>
        <NoteDialog
          note={noteValue}
          updateNote={(value) => setNoteValue(value)}
          isOpen={noteDialogOpen}
          closeDialog={() => onNoteDialogClose(false)}
          onSave={() => onNoteDialogClose(true)}
        />
        <Collapse in={showCow} {...(showCow ? { timeout: 0 } : { timeout: 500 })}>
          <CowCard
            index={cowIndex}
            cowData={cowData}
            pressedContinueButton={pressedContinueButton}
            updateCowProperty={updateCowProperty}
            openNoteDialog={() => setNoteDialogOpen(true)}
            onDeleteClick={() => setShowCow(false)}
            toggleCowReason={toggleCowReason}
          />
        </Collapse>
      </div>
    </Fade>
  );
}

Cow.propTypes = {
  cowIndex: PropTypes.number.isRequired,
  cowData: PropTypes.object.isRequired,
  deleteCow: PropTypes.func.isRequired,
  updateCowProperty: PropTypes.func.isRequired,
  toggleCowReason: PropTypes.func.isRequired,
  pressedContinueButton: PropTypes.bool.isRequired,
};
