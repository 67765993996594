import React, { useState, useEffect } from 'react';
import { makeStyles, useMediaQuery, Typography } from '@material-ui/core';
import blackWhiteCow from '../../assets/ko-holstein.png';
import brownCow from '../../assets/ko-srb.png';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  noCowsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& p': {
      color: theme.palette.grays.gray80,
      textAlign: 'center',
    },
  },
  cowImage: {
    maxWidth: '256px',
    paddingBottom: theme.spacing(1),
  },
  cowImageMobile: {
    maxWidth: '156px',
    paddingBottom: theme.spacing(1),
  },
}));

export default function ToDoNoCows() {
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const [cow, setCow] = useState(null);
  const { t } = useTranslation('to-do');

  useEffect(() => {
    // Randomize which one of two cows should be displayed
    if (Math.floor(Math.random() * 2) + 1 < 2) {
      setCow(brownCow);
    } else {
      setCow(blackWhiteCow);
    }
  }, []);

  return (
    <div className={classes.noCowsWrapper}>
      <img
        src={cow}
        alt='Cow'
        className={isMobile ? classes.cowImageMobile : classes.cowImage}
      ></img>
      <Typography>{t('noCows')}</Typography>
    </div>
  );
}
