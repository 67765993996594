import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export const SnackbarContext = createContext({});

export const SnackbarProvider = (props) => {
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation('snackbar');
  const [snackbar, setSnackbar] = useState({
    text: '',
    severity: '',
    open: false,
  });

  return (
    <SnackbarContext.Provider value={{ setSnackbar }}>
      {props.children}
      <Snackbar
        style={{
          zIndex: '7000',
          // To adjust snackbar depending on if its mobile device.
          // Login side does not have a menu and therefore the snackbar should not have 20% padding.
          paddingLeft: isMobile ? '' : localStorage.getItem('siteid') ? '20%' : '',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={snackbar.open}
        autoHideDuration={snackbar.severity === 'warning' ? 5000 : 3000}
        onClose={() => setSnackbar({ text: snackbar.text, severity: 'warning', open: false })}
      >
        <MuiAlert elevation={6} variant='filled' severity={snackbar.severity}>
          {t(snackbar.text)}
        </MuiAlert>
      </Snackbar>
    </SnackbarContext.Provider>
  );
};

SnackbarProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};
