import React, { useState, useEffect, useContext } from 'react';
import {
  makeStyles,
  Paper,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DataService from '../../Services/DataService';
import { SnackbarContext } from '../../Contexts/SnackbarContext';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    boxShadow: theme.shadows.small,
    borderRadius: '4px',
  },
  descriptionWrapper: {
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grays.gray20}`,
  },
  optionsContainer: {
    width: '100%',
    '& label+label': {
      borderTop: `1px solid ${theme.palette.grays.gray20}`,
    },
  },
  option: {
    margin: 0,
    padding: theme.spacing(0.5),
  },
  subHeader: {
    color: theme.palette.grays.gray80,
  },
}));

export default function SettingsRadioButtons(props) {
  const { settingsType, options, currentSettings, reloadSettings } = props;
  const { setSnackbar } = useContext(SnackbarContext);
  const classes = useStyles();
  const { t } = useTranslation('settings');
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (currentSettings) {
      setSelectedOption(currentSettings);
    }
  }, [currentSettings]);

  const Description = () => {
    return (
      <div className={classes.descriptionWrapper}>
        <Typography variant='h5' gutterBottom>
          {t(`${settingsType}Title`)}
        </Typography>
        <Typography variant='body1' className={classes.subHeader}>
          {t('affectsActions')}
        </Typography>
      </div>
    );
  };

  const handleChangeOption = (event) => {
    if (settingsType === 'preparation') {
      savePreparationOption(parseInt(event.target.value));
    } else if (settingsType === 'method') {
      saveMethodOption(parseInt(event.target.value));
    }
  };

  async function savePreparationOption(preparation) {
    setIsLoading(true);
    await DataService.putObject(
      'KetoskollSiteSettings/SetSiteEnergyType',
      {
        energyType: preparation,
      },
      '',
    ).then((response) => {
      if (response instanceof Error) {
        setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
      }
      setIsLoading(false);
      reloadSettings();
    });
  }

  async function saveMethodOption(method) {
    setIsLoading(true);
    await DataService.putObject(
      'KetoskollSiteSettings/SetMeasurementPackageType',
      {
        measurementPackageType: method,
      },
      '',
    ).then((response) => {
      if (response instanceof Error) {
        setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
      }
      setIsLoading(false);
      reloadSettings();
    });
  }

  const Options = () => {
    return (
      <FormControl component='fieldset' classes={{ root: classes.optionsContainer }}>
        <RadioGroup aria-label={settingsType} value={selectedOption} onChange={handleChangeOption}>
          {options.map((option) => {
            return (
              <FormControlLabel
                key={option.key}
                value={option.value.toString()}
                control={<Radio color='primary' disabled={isLoading} />}
                label={option.title}
                classes={{ root: classes.option }}
              />
            );
          })}
        </RadioGroup>
      </FormControl>
    );
  };

  return (
    <Paper className={classes.wrapper} elevation={0}>
      <Description />
      <Options />
    </Paper>
  );
}

SettingsRadioButtons.propTypes = {
  settingsType: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  currentSettings: PropTypes.string,
  reloadSettings: PropTypes.func.isRequired,
};
