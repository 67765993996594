import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getActionPackageType } from '../../../../../Services/Utility/MeasurementInformation';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  measurementContainer: {
    padding: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    display: 'grid',
    gridTemplateColumns: '25% 30% 30%',
    alignItems: 'center',
  },
  earlyKetosis: {
    border: `1px solid ${theme.palette.complementColors.darkRed}`,
  },
  lateKetosis: {
    border: `1px solid ${theme.palette.complementColors.darkYellow}`,
  },
  continuedKetosis: {
    border: `1px solid ${theme.palette.complementColors.darkYellow}`,
  },
}));

export default function ResultMeasurement(props) {
  const { actionPackage, measurement } = props;
  const classes = useStyles();
  const { t } = useTranslation('actionPackage');
  const actionPackageType = actionPackage
    ? getActionPackageType(actionPackage.actionPackageId)
    : null;

  function getBorderColor() {
    if (actionPackageType === 'earlyKetosis') {
      return classes.earlyKetosis;
    } else if (actionPackageType === 'lateKetosis') {
      return classes.lateKetosis;
    } else if (actionPackageType === 'continuedKetosis') {
      return classes.continuedKetosis;
    } else {
      return '';
    }
  }

  return (
    <div className={`${classes.measurementContainer} ${getBorderColor()}`}>
      <Typography variant='h5'>{measurement.subject.name}</Typography>
      <Typography>{`${t('DIM')}: ${measurement.daysInMilk}`}</Typography>
      <Typography>{`BHB: ${measurement.bhbValue}`}</Typography>
    </div>
  );
}

ResultMeasurement.propTypes = {
  actionPackage: PropTypes.object.isRequired,
  measurement: PropTypes.object.isRequired,
};
