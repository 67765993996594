import React, { useContext, useEffect, useState, useCallback } from 'react';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import {
  makeStyles,
  useMediaQuery,
  Typography,
  Dialog,
  Button,
  CircularProgress,
} from '@material-ui/core';
import ToDoHeader from './ToDoHeader';
import ToDoNoCows from './ToDoNoCows';
import ActionGroup from './ActionGroup';
import DataService from '../../Services/DataService';
import { Redirect } from 'react-router-dom';
import OnBoarding from './OnBoarding';
import { enums } from '../../Services/Enumerations';
import NewlyCalvedCows from './ToDoNewlyCalvedCows';
import SecondTestCows from './ToDoSecondTest';

const useStyles = makeStyles((theme) => ({
  toDoWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    overflow: 'auto',
    padding: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  desktopContainer: {
    width: '50%',
  },
  dialog: {
    padding: theme.spacing(2),
    maxWidth: theme.spacing(15),
    '& button': {
      marginTop: theme.spacing(2),
    },
  },
  listWrapper: {
    width: '100%',
    '&>div:last-of-type': {
      marginBottom: theme.spacing(5),
    },
  },
  spinner: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: 0,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function ToDo() {
  const { t } = useTranslation('to-do');
  const { setHeaderTitle } = useContext(HeaderContext);
  const { setSnackbar } = useContext(SnackbarContext);
  const { canRegister } = useContext(UserContext);
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isOnboardingComplete, setIsOnBoardingComplete] = useState(true);
  const [currentSiteSettings, setCurrentSiteSettings] = useState({});
  const [todos, setTodos] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(new Date()); // Date-based pagination
  const [newlyCalvedCows, setNewlyCalvedCows] = useState([]);
  const [secondTestCows, setSecondTestCows] = useState([]);

  const actionGroups = [
    { type: enums.actionType.counselings, actions: todos.counselings },
    { type: enums.actionType.treatments, actions: todos.treatments },
    { type: enums.actionType.tests, actions: todos.ketosisTests },
  ];

  const onPageUpdated = useCallback((page) => {
    setPage(page);
  }, []);

  const onSavedSettingsSuccessfully = useCallback(() => {
    setIsOnBoardingComplete(true);
  }, []);

  const fetchTodos = useCallback(async () => {
    setIsLoading(true);

    const actionPlans = await DataService.fetchData('Action/GetActionPlansByDate', {
      chosenDate: page.toISOString(),
    });

    const newlyCalvedCows = await DataService.fetchData('Cow/GetCowsWithoutKetosisMeasurement', {
      currentDay: page.toISOString(),
    });

    const secondTestCows = await DataService.fetchData('Cow/GetCowsWithOneNegativeKetosisMeasurement', {
      currentDay: page.toISOString(),
    });

    if (actionPlans instanceof Error) {
      setSnackbar({ text: 'fetchToDoError', severity: 'warning', open: true });
    } else {
      setTodos(actionPlans);
    }

    if (newlyCalvedCows instanceof Error) {
      setSnackbar({ text: 'fetchNewlyCalvedCowsErrors', severity: 'warning', open: true });
    } else {
      setNewlyCalvedCows(newlyCalvedCows);
    }

    if (secondTestCows instanceof Error) {
      setSnackbar({ text: 'fetchSecondTestCowsErrors', severity: 'warning', open: true });
    } else {
      setSecondTestCows(secondTestCows);
    }

    setIsLoading(false);
  }, [page, setSnackbar]);

  useEffect(() => {
    setHeaderTitle(t('to-do'));
    document.title = `${t('to-do')} | KetosKoll`;
  }, [setHeaderTitle, t]);

  useEffect(() => {
    if (localStorage.getItem('siteid') && localStorage.getItem('siteid') !== 'null') {
      fetchTodos();
    }
  }, [fetchTodos]);

  useEffect(() => {
    if (localStorage.getItem('siteid') && localStorage.getItem('siteid') !== 'null') {
      fetchOnboardingStatus();
    }
  }, []);

  if (!canRegister) {
    return <Redirect to='/measurements' />;
  }

  async function fetchOnboardingStatus() {
    const onboardingResponse = await DataService.fetchData(
      'KetoskollSiteSettings/GetOnBoardingStatus',
    );
    if (onboardingResponse instanceof Error) {
      console.log('Got erronous response when fetching onboarding status.', onboardingResponse);
    } else {
      setIsOnBoardingComplete(onboardingResponse.onBoardingComplete);
      setCurrentSiteSettings(onboardingResponse.siteSettings);
    }
  }

  const Spinner = () => (
    <div className={classes.spinner}>
      <CircularProgress color='primary' size='4rem' />
    </div>
  );

  const DayDialog = () => {
    return (
      <Dialog
        open={isDialogOpen}
        classes={{ paper: classes.dialog }}
        onClose={() => setIsDialogOpen(false)}
      >
        <Typography>{t('daysSinceKetosisWasDiscovered')}</Typography>
        <Button
          variant='contained'
          disableElevation
          color='primary'
          size='large'
          onClick={() => setIsDialogOpen(false)}
          fullWidth={isMobile}
        >
          OK
        </Button>
      </Dialog>
    );
  };

  function noData() {
    if (Object.values(todos).every((group) => group.length === 0) && newlyCalvedCows.length === 0 && secondTestCows.length === 0) {
      return true;
    } else return false;
  }

  return (
    <>
      <div className={classes.toDoWrapper}>
        <div
          className={
            isMobile ? classes.container : `${classes.container} ${classes.desktopContainer}`
          }
        >
          <ToDoHeader
            onPageUpdated={onPageUpdated}
            isLoadingPage={isLoading}
            isCurrentPageEmpty={noData(todos)}
          />
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              {noData(todos) || !isOnboardingComplete ? (
                <ToDoNoCows />
              ) : (
                <>
                  <div className={classes.listWrapper}>
                    {actionGroups.map((actionGroup) => {
                      return (
                        <ActionGroup
                          key={actionGroup.type}
                          type={actionGroup.type}
                          openDialog={setIsDialogOpen}
                          actions={actionGroup.actions}
                          activePage={page}
                          refreshTodos={fetchTodos}
                        />
                      );
                    })}
                    {newlyCalvedCows.length > 0 && (
                      <NewlyCalvedCows cows={newlyCalvedCows} pageDate={page} />
                    )}
                    {secondTestCows.length > 0 && (
                      <SecondTestCows cows ={secondTestCows} pageDate={page} />
                    )}
                  </div>
                  <DayDialog />
                </>
              )}
            </>
          )}
        </div>
      </div>

      {!isOnboardingComplete && (
        <OnBoarding
          currentSiteSettings={currentSiteSettings}
          isOnboardingComplete={isOnboardingComplete}
          onSavedSettingsSuccessfully={onSavedSettingsSuccessfully}
        />
      )}
    </>
  );
}
