import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextField, Typography, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { leftZeroPad, getCheckSum } from '../../../Services/Utility/CowUtils';

const useStyles = makeStyles((theme) => ({
  editWrapper: {
    display: 'flex',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.7),
    gap: '8px',
    flexWrap: 'wrap',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  landCodeInput: {
    maxWidth: '80px',
  },
  checkSum: {
    whiteSpace: 'nowrap',
    margin: 'auto 0',
  },
  noteButton: {
    padding: '9px 0',
    '& svg': {
      marginRight: theme.spacing(0.5),
      color: theme.palette.primary.main,
    },
    '& p': {
      textAlign: 'start',
    },
  },
  birthPlaceId: {
    flexGrow: 1,
  },
}));

export default function CowCardFullIdentity(props) {
  const { cowData, index, updateCowProperty, pressedContinueButton } = props;
  const classes = useStyles();
  const { t } = useTranslation('formComponent');
  const [editMode, setEditMode] = useState(
    !cowData.identifier || !cowData.prefix || !cowData.birthPlaceId,
  );
  const [invalidbirthPlaceId, setInvalidBirthPlaceId] = useState(true);
  const [invalidLandcode, setInvalidLandcode] = useState(true);

  useEffect(() => {
    setInvalidBirthPlaceId(
      !Number.isInteger(Number.parseFloat(cowData.birthPlaceId)) ||
        Math.sign(cowData.birthPlaceId) !== 1,
    );
    setInvalidLandcode(!cowData.prefix);
  }, [cowData.birthPlaceId, cowData.prefix]);

  useEffect(() => {
    if (!cowData.identifier || !cowData.prefix || !cowData.birthPlaceId) {
      setEditMode(true);
    }
  }, [cowData.identifier, cowData.prefix, cowData.birthPlaceId]);

  return editMode ? (
    <div className={classes.editWrapper}>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 2 }}
        label={t('landCode')}
        className={classes.landCodeInput}
        variant='outlined'
        id={`cowNumber-${index}-prefix`}
        data-idx={index}
        name='prefix'
        value={cowData.prefix}
        onChange={(e) => {
          updateCowProperty(index, e.target.name, e.target.value);
        }}
        size='small'
        error={pressedContinueButton && invalidLandcode}
        helperText={pressedContinueButton && invalidLandcode ? t('invalidInput') : ''}
      />
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        label={t('ppn')}
        error={pressedContinueButton && invalidbirthPlaceId}
        helperText={pressedContinueButton && invalidbirthPlaceId ? t('invalidInput') : ''}
        variant='outlined'
        id={`cowNumber-${index}-birthPlaceId`}
        data-idx={index}
        name='birthPlaceId'
        value={cowData.birthPlaceId}
        type='number'
        onInput={(e) => (e.target.value = e.target.value.slice(0, 6))}
        onChange={(e) => {
          updateCowProperty(index, e.target.name, parseInt(e.target.value));
          updateCowProperty(index, 'checkSum', getCheckSum(e.target.value, cowData.identifier));
        }}
        size='small'
        className={classes.birthPlaceId}
      />
      <Typography variant='body2' className={classes.checkSum}>
        {t('checkSum')}: <strong>{cowData.checkSum}</strong>
      </Typography>
    </div>
  ) : (
    <div className={classes.wrapper}>
      <Typography variant='body2'>
        {cowData.prefix}-{leftZeroPad(cowData.birthPlaceId, 6)}-
        <strong>{leftZeroPad(cowData.identifier, 4)}</strong>-{cowData.checkSum}
      </Typography>
      <Button
        variant='text'
        color='primary'
        className={classes.noteButton}
        onClick={() => setEditMode(true)}
      >
        <EditIcon />
        <Typography variant='body2'>{t('edit')}</Typography>
      </Button>
    </div>
  );
}

CowCardFullIdentity.propTypes = {
  index: PropTypes.number.isRequired,
  cowData: PropTypes.object.isRequired,
  updateCowProperty: PropTypes.func.isRequired,
  pressedContinueButton: PropTypes.bool.isRequired,
};
