import { CircularProgress, makeStyles } from '@material-ui/core';
import React, { Suspense, useContext, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { FormProvider } from '../../Contexts/FormContext';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import DataService from '../../Services/DataService';
import { enums } from '../../Services/Enumerations';
import { groupResultsOnResultType } from '../../Services/Utility/MeasurementInformation';
import Form from '../Form';
import Measurements from '../Measurements';
import ReasonType from '../ReasonType';
import Result from '../Result';
import Settings from '../Settings';
import TestType from '../TestType';
import ToDo from '../ToDo';

const useStyles = makeStyles({
  wrapper: {
    justifyContent: 'center',
    height: '100%',
    minHeight: 'calc(100% - 8rem)',
    background: '#F4F4F4',
    position: 'relative',
  },
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
  },
});

export default function Start() {
  const classes = useStyles();
  const { setSnackbar } = useContext(SnackbarContext);
  const [testType, setTestType] = useState(enums.BhbMeasurementMethod.NotSet);
  const [reasonType, setReasonType] = useState(enums.MeasurementReason.NotSet);
  const [resultData, setResultData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const Spinner = () => (
    <div className={classes.spinner}>
      <CircularProgress />
    </div>
  );

  function getResultsFromPosts(cows, timestamp) {
    setIsLoading(true);
    setResultData({}); // make sure any old values are cleared
    (async () => {
      try {
        const cowsToSubmit = cows.map((cow) => {
          const tempCow = { ...cow };
          tempCow.measuredAt = timestamp;
          return tempCow;
        });
        const cowResponse = await DataService.postObject(
          'Measurement/PostMeasurement',
          cowsToSubmit,
        ).then((response) => {
          if (response === undefined) {
            // did not get a response, server probably down
            setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
            return null;
          }
          return response;
        });
        if (cowResponse instanceof Error) {
          setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
          setResultData([]);
        } else if (cowResponse) {
          setResultData(groupResultsOnResultType(cowResponse));
        }
        setIsLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }

  return (
    <FormProvider>
      <div className={classes.wrapper}>
        <Suspense fallback={<Spinner />}>
          <Switch>
            <Route path={`/new-test`} exact render={() => <TestType setTestType={setTestType} />} />
            <Route
              path={`/reason`}
              exact
              render={() => <ReasonType testType={testType} setReasonType={setReasonType} />}
            />
            <Route
              path={`/form`}
              exact
              render={() => (
                <Form
                  testType={testType}
                  reasonType={reasonType}
                  getResultsFromPostsCallback={getResultsFromPosts}
                />
              )}
            />
            <Route
              path={`/result`}
              exact
              render={() => <Result results={resultData} isLoading={isLoading} />}
            />
            <Route path={`/`} exact render={() => <ToDo />} />
            <Route path={`/measurements`} exact render={() => <Measurements />} />
            <Route path={`/settings`} exact render={() => <Settings />} />
          </Switch>
        </Suspense>
      </div>
    </FormProvider>
  );
}
