import React, { useState, useEffect, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { FormContext } from '../../../Contexts/FormContext';
import {
  formatCowNameBoldAnimalNumber,
  getCheckSum,
  getDIM,
  leftZeroPad,
} from '../../../Services/Utility/CowUtils';
import { enums } from '../../../Services/Enumerations';
import DataService from '../../../Services/DataService';
import { differenceInDays } from 'date-fns';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
}));

export default function CowCardCowNumberInput(props) {
  const { invalid, index, updateCowProperty, cowData, onReasonChange } = props;
  const classes = useStyles();
  const { t } = useTranslation('formComponent');
  const { existingCows, selectedDate, PPN } = useContext(FormContext);
  const [cowOptions, setCowOptions] = useState([]);

  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);

  const handleOpen = useCallback(() => {
    if (inputValue.length > 0) {
      setOpen(true);
    }
  }, [inputValue.length]);

  const handleInputChange = useCallback(
    (_, newInputValue, reason) => {
      if (reason === 'clear') {
        updateCowProperty(index, 'daysInMilk', '');
      }

      setInputValue(newInputValue);
      if (newInputValue.length > 0) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    },
    [index, updateCowProperty],
  );

  const fetchCow = useCallback(async (id) => {
    const cow = await DataService.fetchDataById('Cow/GetCow', '', { cowId: id });

    if (cow instanceof Error) {
      return null;
    } else {
      return cow;
    }
  }, []);

  const isFirstTimeCalver = useCallback(
    (cow) => {
      return (
        cow.calfCount <= 1 &&
        cow.firstCalvingDate &&
        differenceInDays(selectedDate, new Date(cow.firstCalvingDate)) <= 200
      );
    },
    [selectedDate],
  );

  const onChangeCowNumber = useCallback(
    async (value) => {
      // MUI's Autocomplete returns different formats of input values depending on if the
      // value was picked from the list, added as new value or typed in by the user.
      const identifier = value?.inputValue || value?.identifier || value;

      // Check for matches with existing cows
      const cow = existingCows.find(
        (cow) =>
          leftZeroPad(cow.animalNumber, 4) === identifier ||
          cow.animalNumber.toString() === identifier,
      );

      // Update other values based on identifier
      const prefix = cow?.prefix || 'SE';
      const birthPlaceId = cow?.birthPlaceId || PPN || '';
      const checkSum = cow?.checkSum ?? getCheckSum(birthPlaceId, identifier);
      const daysInMilk = cow?.lastCalvingDate
        ? getDIM(new Date(cow.lastCalvingDate), selectedDate).toString()
        : '';
      const animalNumber = cow?.animalNumber || parseInt(identifier);
      const lastCalvingDate = cow?.lastCalvingDate || '';

      // Fetch additional data about the selected cow
      if (cow?.id) {
        const detailCow = await fetchCow(cow.id);

        // Decide if the selected cow is a first calf heifer
        if (detailCow && isFirstTimeCalver(detailCow)) {
          if (!cowData.reasons.includes(enums.MeasurementReason.FirstTimeCalver)) {
            onReasonChange(enums.MeasurementReason.FirstTimeCalver);
          }
        } else {
          if (cowData.reasons.includes(enums.MeasurementReason.FirstTimeCalver)) {
            onReasonChange(enums.MeasurementReason.FirstTimeCalver);
          }
        }
      }

      updateCowProperty(index, 'identifier', identifier);
      updateCowProperty(index, 'animalNumber', animalNumber);
      updateCowProperty(index, 'birthPlaceId', birthPlaceId);
      updateCowProperty(index, 'checkSum', checkSum);
      updateCowProperty(index, 'prefix', prefix);
      updateCowProperty(index, 'daysInMilk', daysInMilk);
      updateCowProperty(index, 'lastCalvingDate', lastCalvingDate);
    },
    [
      PPN,
      cowData.reasons,
      existingCows,
      fetchCow,
      index,
      isFirstTimeCalver,
      onReasonChange,
      selectedDate,
      updateCowProperty,
    ],
  );

  // Transform list of cows to option list instead of using the original list directly.
  // MUI's Autocomplete crashes if animalNumber is a number and not a string for some reason.
  useEffect(() => {
    if (existingCows.length > 0) {
      setCowOptions(
        existingCows.map((cow) => {
          return {
            identifier: cow.animalNumber.toString(),
            label: (
              <Typography variant='body2'>
                {cow.animalNumber} ({formatCowNameBoldAnimalNumber(cow)})
              </Typography>
            ),
          };
        }),
      );
    }
  }, [existingCows]);

  return (
    <Autocomplete
      className={classes.input}
      id={`cowNumber-${index}-nr`}
      data-idx={index}
      name='identifier'
      onChange={(_, newValue) => {
        onChangeCowNumber(newValue);
      }}
      value={cowData.identifier}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.identifier;
      }}
      renderOption={(option) => {
        return option.label;
      }}
      options={cowOptions}
      freeSolo
      onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
      autoSelect
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          label={t('cowNumber')}
          size='small'
          error={invalid}
          helperText={invalid ? t('invalidInput') : ''}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            type: 'number',
          }}
        />
      )}
      filterOptions={(options, state) => {
        const { inputValue } = state;

        const filteredOptions = options.filter(
          (item) =>
            // handle search string with or without leading zeros
            leftZeroPad(item.identifier, 4).startsWith(inputValue) ||
            item.identifier.startsWith(inputValue),
        );

        // Suggest the creation of a new value
        const valueInputExists = filteredOptions.length > 0;

        if (inputValue !== '' && !valueInputExists) {
          filteredOptions.push({
            inputValue,
            label: <Typography variant='body2'>{`${t('add')} "${inputValue}"`}</Typography>,
          });
        }

        return filteredOptions;
      }}
      open={open && focus}
      onOpen={handleOpen}
      onClose={() => setOpen(false)}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
    />
  );
}

CowCardCowNumberInput.propTypes = {
  index: PropTypes.number.isRequired,
  updateCowProperty: PropTypes.func.isRequired,
  invalid: PropTypes.bool,
  cowData: PropTypes.object.isRequired,
  onReasonChange: PropTypes.func.isRequired,
};
