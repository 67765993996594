import { enums } from '../../Enumerations';
import Energy from '../../../assets/energy.svg';
import EnergyCalcium from '../../../assets/energy_and_calcium.svg';
import ContactVet from '../../../assets/contact_vet.svg';
import BloodSample from '../../../assets/ketosis_blood.svg';
import MilkSample from '../../../assets/ketosis_milk.svg';
import i18n from '../../../i18n';

// ActionPackagesType
const treatmentIsNotWorkingActionPackages = [enums.actionPackage.StopIneffectiveTreatment];

const suspectedKetosisActionPackages = [
  enums.actionPackage.SuspectedKetosisBlood,
  enums.actionPackage.SuspectedKetosisMilk,
];

const earlyKetosisActionPackages = [
  enums.actionPackage.NewEarlyKetosisGlycerol,
  enums.actionPackage.NewEarlyKetosisGlycerolMilk,
  enums.actionPackage.NewEarlyKetosisOtherEnergy,
  enums.actionPackage.NewEarlyKetosisOtherEnergyMilk,
  enums.actionPackage.NewEarlyKetosisPropyleneGlycol,
  enums.actionPackage.NewEarlyKetosisPropyleneGlycolMilk,
];

const lateKetosisActionPackages = [
  enums.actionPackage.NewLateKetosisGlycerol,
  enums.actionPackage.NewLateKetosisGlycerolMilk,
  enums.actionPackage.NewLateKetosisOtherEnergy,
  enums.actionPackage.NewLateKetosisOtherEnergyMilk,
  enums.actionPackage.NewLateKetosisPropyleneGlycol,
  enums.actionPackage.NewLateKetosisPropyleneGlycolMilk,
];

export const continuedKetosisActionPackages = [
  enums.actionPackage.EarlyKetosisGlycerol,
  enums.actionPackage.EarlyKetosisGlycerolMilk,
  enums.actionPackage.EarlyKetosisOtherEnergy,
  enums.actionPackage.EarlyKetosisOtherEnergyMilk,
  enums.actionPackage.EarlyKetosisPropyleneGlycol,
  enums.actionPackage.EarlyKetosisPropyleneGlycolMilk,
  enums.actionPackage.LateKetosisGlycerol,
  enums.actionPackage.LateKetosisGlycerolMilk,
  enums.actionPackage.LateKetosisOtherEnergy,
  enums.actionPackage.LateKetosisOtherEnergyMilk,
  enums.actionPackage.LateKetosisPropyleneGlycol,
  enums.actionPackage.LateKetosisPropyleneGlycolMilk,
];

const noKetosisActionPackages = [enums.actionPackage.NoKetosis];

const recoveredFromKetosisActionPackages = [enums.actionPackage.HealthyAfterKetosis];

// ResultTypes
const cancelTreatmentResultGroup = [...treatmentIsNotWorkingActionPackages];
const newTestRequiredResultGroup = [...suspectedKetosisActionPackages];
const treatmentRequiredResultGroup = [
  ...earlyKetosisActionPackages,
  ...lateKetosisActionPackages,
  ...continuedKetosisActionPackages,
];
const noActionRequiredResultGroup = [
  ...noKetosisActionPackages,
  ...recoveredFromKetosisActionPackages,
];

const lateRegistrationResultGroup = [
  enums.actionPackage.LateRegistrationNewLateKetosis,
  enums.actionPackage.LateRegistrationNewEarlyKetosis,
  enums.actionPackage.LateRegistrationKetosis,
  enums.actionPackage.LateRegistrationSuspectedKetosis,
];

const translateActionPackage = {
  [enums.actionPackage.LateKetosisGlycerol]: enums.actionPackage.EarlyKetosisGlycerol,
  [enums.actionPackage.LateKetosisGlycerolMilk]: enums.actionPackage.EarlyKetosisGlycerolMilk,
  [enums.actionPackage.LateKetosisOtherEnergy]: enums.actionPackage.EarlyKetosisOtherEnergy,
  [enums.actionPackage.LateKetosisOtherEnergyMilk]: enums.actionPackage.EarlyKetosisOtherEnergyMilk,
  [enums.actionPackage.LateKetosisPropyleneGlycol]: enums.actionPackage.EarlyKetosisPropyleneGlycol,
  [enums.actionPackage.LateKetosisPropyleneGlycolMilk]:
    enums.actionPackage.EarlyKetosisPropyleneGlycolMilk,
};

// IconTypes
const giveEnergy = [
  enums.actionName.givePG,
  enums.actionName.giveGlycerol,
  enums.actionName.giveOtherEnergy,
];
const giveCalciumAndEnergy = [
  enums.actionName.giveCalciumAndPG,
  enums.actionName.giveCalciumAndGlycerol,
  enums.actionName.giveCalciumAndOtherEnergy,
];

export function groupResultsOnResultType(results) {
  const groupedResults = {
    cancelTreatment: [],
    newTest: [],
    treatment: [],
    noAction: [],
    lateRegistration: [],
  };

  for (const actionPackageId in results) {
    if (cancelTreatmentResultGroup.includes(actionPackageId)) {
      groupedResults.cancelTreatment.push(results[actionPackageId]);
    } else if (newTestRequiredResultGroup.includes(actionPackageId)) {
      groupedResults.newTest.push(results[actionPackageId]);
    } else if (treatmentRequiredResultGroup.includes(actionPackageId)) {
      if (actionPackageId in translateActionPackage) {
        results[actionPackageId].actionPackage.actionPackageId =
          translateActionPackage[actionPackageId];
      }

      const existingTreatment = groupedResults.treatment.find(
        (e) =>
          e.actionPackage.actionPackageId ===
          results[actionPackageId].actionPackage.actionPackageId,
      );

      if (existingTreatment) {
        existingTreatment.measurements.push(...results[actionPackageId].measurements);
      } else {
        groupedResults.treatment.push(results[actionPackageId]);
      }
    } else if (noActionRequiredResultGroup.includes(actionPackageId)) {
      groupedResults.noAction.push(results[actionPackageId]);
    } else if (lateRegistrationResultGroup.includes(actionPackageId)) {
      groupedResults.lateRegistration.push(results[actionPackageId]);
    }
  }

  return groupedResults;
}

export function getResultType(id) {
  if (cancelTreatmentResultGroup.includes(id)) {
    return 'cancelTreatment';
  } else if (
    [...newTestRequiredResultGroup, enums.actionPackage.LateRegistrationSuspectedKetosis].includes(
      id,
    )
  ) {
    return 'newTest';
  } else if (
    [
      ...treatmentRequiredResultGroup,
      enums.actionPackage.LateRegistrationKetosis,
      enums.actionPackage.LateRegistrationNewEarlyKetosis,
      enums.actionPackage.LateRegistrationNewLateKetosis,
    ].includes(id)
  ) {
    return 'treatment';
  } else if (noActionRequiredResultGroup.includes(id)) {
    return 'noAction';
  }
}

export function getActionPackageType(id) {
  if (treatmentIsNotWorkingActionPackages.includes(id)) {
    return 'treatmentIsNotWorking';
  } else if (
    [
      ...suspectedKetosisActionPackages,
      enums.actionPackage.LateRegistrationSuspectedKetosis,
    ].includes(id)
  ) {
    return 'suspectedKetosis';
  } else if (
    [...earlyKetosisActionPackages, enums.actionPackage.LateRegistrationNewEarlyKetosis].includes(
      id,
    )
  ) {
    return 'earlyKetosis';
  } else if (
    [...lateKetosisActionPackages, enums.actionPackage.LateRegistrationNewLateKetosis].includes(id)
  ) {
    return 'lateKetosis';
  } else if (
    [...continuedKetosisActionPackages, enums.actionPackage.LateRegistrationKetosis].includes(id)
  ) {
    return 'continuedKetosis';
  } else if (noKetosisActionPackages.includes(id)) {
    return 'noKetosis';
  } else if (recoveredFromKetosisActionPackages.includes(id)) {
    return 'recoveredFromKetosis';
  } else {
    return '';
  }
}

export function getMeasurementMethod(method) {
  if (method === enums.BhbMeasurementMethod.Blood) {
    return i18n.t('measurements:bloodTest');
  } else if (method === enums.BhbMeasurementMethod.Milk) {
    return i18n.t('measurements:milkTest');
  } else {
    return '';
  }
}

export function getReason(reason) {
  switch (reason) {
    case enums.MeasurementReason.Symptom:
      return i18n.t('measurements:symptom');
    case enums.MeasurementReason.StrategicSelection:
      return i18n.t('measurements:strategically');
    case enums.MeasurementReason.FirstTimeCalver:
      return i18n.t('measurements:firstTimeCalver');
    case enums.MeasurementReason.KnownKetosisReason:
      return i18n.t('measurements:knownKetosisReason');
    default:
      return '';
  }
}

export function getIconType(actionText) {
  if (giveEnergy.includes(actionText)) {
    return Energy;
  } else if (giveCalciumAndEnergy.includes(actionText)) {
    return EnergyCalcium;
  } else if (actionText === enums.actionName.stopIneffectiveTreatment) {
    return ContactVet;
  } else if (actionText === enums.actionName.takeBloodSample) {
    return BloodSample;
  } else if (actionText === enums.actionName.takeMilkSample) {
    return MilkSample;
  } else {
    return null;
  }
}

export function getPreparationOptions() {
  return Object.keys(enums.energyType)
    .filter((name) => ![enums.energyType.notSet].includes(enums.energyType[name]))
    .map((name) => {
      return {
        key: enums.energyType[name],
        value: enums.energyType[name],
        title: i18n.t(`settings:${name}`),
      };
    });
}

export function getSampleMethodOptions() {
  return Object.keys(enums.measurementPackageType)
    .filter(
      (name) => ![enums.measurementPackageType.notSet].includes(enums.measurementPackageType[name]),
    )
    .map((name) => {
      return {
        key: enums.measurementPackageType[name],
        value: enums.measurementPackageType[name],
        title: i18n.t(`settings:${name}`),
      };
    });
}
