/**
 * Function for changing the format on a Date
 * @return {string} The returning value is a string with the format YYYY-MM-DD
 */
export function yyyymmdd(date) {
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  const yyyy = date.getFullYear();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return yyyy + '-' + mm + '-' + dd;
}

/**
 * Function for changing the format on a Date
 * @return {string} The returning value is a string with the format YYYY-MM-DD HH:MM
 */
export function yyyymmddhhmm(date) {
  let mm = date.getMonth() + 1;
  let dd = date.getDate();
  const yyyy = date.getFullYear();
  let hh = date.getHours();
  let min = date.getMinutes();

  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  if (min < 10) {
    min = '0' + min;
  }
  if (hh < 10) {
    hh = '0' + hh;
  }
  return yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + min;
}
