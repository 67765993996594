import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: '80%',
    maxWidth: theme.spacing(25),
  },
}));

export default function CowNoteDialog(props) {
  const { isOpen, closeDialog, note, updateNote, onSave } = props;
  const classes = useStyles();
  const { t } = useTranslation('formComponent');

  return (
    <Dialog
      open={isOpen}
      onClose={closeDialog}
      color='primary'
      aria-labelledby='form-dialog-title'
      classes={{ paper: classes.dialog }}
    >
      <DialogTitle id='form-dialog-title'>{t('addNoteHeader')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin='dense'
          id='noteField'
          label={t('noteHint')}
          type='text'
          multiline
          fullWidth
          value={note}
          onChange={(e) => updateNote(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color='primary' onClick={closeDialog}>
          {t('cancel')}
        </Button>
        <Button color='primary' onClick={onSave}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CowNoteDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  updateNote: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  note: PropTypes.string,
};
