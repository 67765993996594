import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createTheme, MuiThemeProvider, CircularProgress } from '@material-ui/core';
import red from '@material-ui/core/colors/red';
import App from './Components/App';
import './i18n';
import 'typeface-roboto';
require('dotenv').config();

const theme = createTheme({
  palette: {
    text: {
      primary: '#242527',
      secondary: '#696A6C',
    },
    primary: {
      light: '#80e27e',
      main: '#009d58',
      dark: '#295035',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#EB6E21',
      main: '#DB6014',
      dark: '#C85712',
      contrastText: '#FFFFFF',
    },
    error: {
      light: red[300],
      main: red[500],
      dark: red[700],
    },
    background: {
      default: '#F4F4F4',
    },
    grays: {
      gray100: '#242526',
      gray90: '#3B3C3E',
      gray80: '#525355',
      gray70: '#696A6C',
      gray60: '#808183',
      gray50: '#979899',
      gray40: '#AEAFB0',
      gray30: '#C6C6C7',
      gray20: '#DDDDDE',
      gray10: '#F4F4F4',
      gray5: '#F9F9F9',
    },
    complementColors: {
      blueGrey: '#425068',
      blueGrey80: '#D9DCE1',
      yellow: '#FFA500',
      brightYellow: '#F9FAB1',
      lightYellow: '#FCF4e1',
      lightYellow10: '#FFE3B8',
      lightRed: '#FFC0B1',
      lightRed50: '#FBDDD6',
      lightRed40: '#F7CCCC',
      lightGreen: '#C6E8D9',
      lightGreen10: '#CCE6E2',
      lightTeal: ' #D3E0D9',
      blackBlue: '#2E3A50',
      darkRed: '#AF4242',
      darkYellow: '#E38801',
      green: '#00846F',
    },
  },

  // Use 16px scaling factor instead of MUI default 8px
  spacing: (factor) => `${factor}rem`,

  shape: {
    borderRadius: 5,
  },

  typography: {
    fontFamily: 'Open Sans, sans-serif',
    h1: {
      fontWeight: 700,
      fontSize: '3.5rem',
      lineHeight: '4rem',
      letterSpacing: '0.25px',
    },
    h2: {
      fontWeight: 700,
      fontSize: '2.5rem',
      lineHeight: '3rem',
      letterSpacing: '0.25px',
    },
    h3: {
      fontWeight: 700,
      fontSize: '1.5rem',
      lineHeight: '2rem',
      letterSpacing: '0.25px',
    },
    h4: {
      fontWeight: 700,
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.25px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.25rem',
      letterSpacing: '0.25px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      letterSpacing: '0.25px',
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: '2rem',
      letterSpacing: '0.25px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      letterSpacing: '0.25px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1rem',
      letterSpacing: '0.25px',
    },
  },

  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
        fontWeight: '600',
        lineHeight: '1rem',
      },
      sizeSmall: {
        padding: 8,
        textTransform: 'none',
        fontWeight: '600',
        lineHeight: '1rem',
        fontSize: '0.875rem',
        maxHeight: '2rem',
      },
      sizeLarge: {
        padding: 16,
        maxHeight: '2.5rem',
      },
    },
    MuiPopover: {
      paper: {
        maxHeight: '300px !important',
      },
    },
    MuiListItem: {
      root: {
        minHeight: '3rem',
      },
    },
  },
  breakpoints: {
    values: {
      // xs, sm, md, lg, and xl are added to support default breakpoints
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      // mobile-first approach breakpoints
      laptop: 1160,
    },
  },
});

// Add custom shadows to theme
theme.shadows.small = '0px 2px 8px rgba(0, 0, 0, 0.05)';
theme.shadows.medium = '0px 4px 16px rgba(0, 0, 0, 0.1)';
theme.shadows.large = '0px 8px 32px rgba(0, 0, 0, 0.15)';

const history = createBrowserHistory();

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CookiesProvider>
      <Router history={history}>
        <Suspense
          fallback={
            <div
              style={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </div>
          }
        >
          <App />
        </Suspense>
      </Router>
    </CookiesProvider>
  </MuiThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
