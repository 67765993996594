/**
 * Static class to check permissions for a signed in user by accessing the storage
 * where the user information is stored.
 */
class PermissionCheck {
  static enums = {
    admin: 'c6e7f8ba-6ca6-49dc-a710-6b1a75c0db72',
    user: 'c57a8d01-5a93-4b1b-be5c-b80193993ae4',
    canViewAllSites: '9b95e50a-97d8-4c11-b17b-0c48d1c13c09',
    canDeleteMeasurements: '820cae5f-f1d0-4a74-b05b-4de3b056604c',
  };

  /**
   * Gets list of usergroup objects from localstorage.
   * Returns false if no such entry, otherwise returns the entries as an array.
   */
  static getUserGroups() {
    const usergroups = localStorage.getItem('usergroups');
    if (null === usergroups || undefined === usergroups) {
      return false;
    }

    return JSON.parse(usergroups);
  }

  /**
   * Checks if ALL of the given permissions exists for the signed in user.
   * @returns {boolean} True if all of permissions exists, false otherwise.
   * @param {String} permissions The permissions to check for.
   */
  static hasAllOfPermissions(...permissions) {
    const usergroups = this.getUserGroups();
    if (!usergroups) {
      return false;
    }

    return usergroups.some((usergroup) => {
      return usergroup.usergrouproles.some((usergrouprole) => {
        return usergrouprole.role.rolepermissions.every((rolepermission) => {
          return permissions.includes(rolepermission.permission.permissionid);
        });
      });
    });
  }

  /**
   * Checks if ANY of the given permissions exists for the signed in user.
   * @returns {boolean} True if any of permissions exists, false otherwise.
   * @param {String} permissions The permissions to check for.
   */
  static hasAnyOfPermissions(...permissions) {
    const usergroups = this.getUserGroups();
    if (!usergroups) {
      return false;
    }

    return usergroups.some((usergroup) => {
      return usergroup.usergrouproles.some((usergrouprole) => {
        return usergrouprole.role.rolepermissions.some((rolepermission) => {
          return permissions.includes(rolepermission.permission.permissionid);
        });
      });
    });
  }

  /**
   * Checks if the permission KetosKoll.Admin exists for any of the user groups
   * belonging to the signed in user.
   * Grants all other permissions.
   */
  static isAdmin() {
    return this.hasAnyOfPermissions(this.enums.admin);
  }

  /**
   * Checks if the permission KetosKoll.User exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to the system.
   */
  static isUser() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.user);
  }

  /**
   * Checks if the permission KetosKoll.CanViewAllSites exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to view data for all sites in the system.
   */
  static canViewAllSites() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canViewAllSites);
  }

  /**
   * Checks if the permission KetosKoll.CanDeleteMeasurements exists for any of the user groups
   * belonging to the signed in user.
   * Grants access to delete measurements from the history
   */
  static canDeleteMeasurements() {
    return this.hasAnyOfPermissions(this.enums.admin, this.enums.canDeleteMeasurements);
  }
}

export default PermissionCheck;
