import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarContext } from '../../Contexts/SnackbarContext';
import DataService from '../../Services/DataService';
import {
  Paper,
  Typography,
  TextField,
  Button,
  InputAdornment,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles((theme) => ({
  settingsPaper: {
    padding: theme.spacing(1),
    boxShadow: theme.shadows.small,
  },
  contentWrapper: {
    paddingTop: '1.5rem !important',
    backgroundColor: '#e9e9e9',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
  },
  passwordRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginTop: '0.3rem',
    flexWrap: 'wrap',
    '& label': {
      color: theme.palette.grays.gray100,
    },
  },
  passwordRowMobile: {
    '&>form+form': {
      marginTop: theme.spacing(2),
    },
  },
  changePasswordColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: '100%',
  },
  errorRow: {
    display: 'flex',
    marginTop: '1rem',
  },
  errorIcon: {
    marginRight: '0.3rem',
  },
  changePasswordButton: {
    marginTop: '1rem',
  },
  mobileChangePasswordButton: {
    marginLeft: 'auto',
  },
  textField: {
    width: '100%',
    '&>div': {
      width: '100%',
    },
  },
  formLabel: {
    '&>.MuiFormLabel-root.Mui-focused': {
      color: theme.palette.grays.gray100,
    },
    '&>.MuiInput-underline::after': {
      borderBottom: `2px solid ${theme.palette.grays.gray100}`,
    },
  },
  iconButton: {
    color: theme.palette.grays.gray100,
  },
  subHeader: {
    color: theme.palette.grays.gray80,
  },
}));

export default function SettingsChangePassword() {
  const classes = useStyles();
  const { t } = useTranslation('settings');
  const isMobile = useMediaQuery('(max-width:550px)');
  const { setSnackbar } = useContext(SnackbarContext);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [changeFail, setChangeFail] = useState(false);
  const [changeSuccess, setChangeSuccess] = useState(false);
  const [errorText, setErrorText] = useState('');

  function scorePassword(password) {
    let score = 0;
    if (!password || password.length < 8) return score;

    const letters = {};
    for (let i = 0; i < password.length; i++) {
      letters[password[i]] = (letters[password[i]] || 0) + 1;
      score += 5.0 / letters[password[i]];
    }

    const variations = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password),
    };

    let variationCount = 0;
    for (const check in variations) {
      variationCount += variations[check] === true ? 1 : 0;
    }
    score += (variationCount - 1) * 10;

    return parseInt(score);
  }

  function checkNewPassword() {
    const score = scorePassword(newPassword);

    if (score >= 60) {
      changePassword();
    } else {
      setChangeFail(true);
      setErrorText('notSecure');
    }
  }

  function changePassword() {
    (async () => {
      try {
        const changePasswordObject = {
          oldPassword: oldPassword,
          newPassword: newPassword,
          modifiedby: localStorage.getItem('userid'),
          modifiedat: new Date(),
        };

        const userResponse = await DataService.putObject(
          'Account/ChangePassword',
          changePasswordObject,
          '',
        ).then((response) => {
          if (response === undefined) {
            // did not get a response, server probably down
            setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
            return null;
          }
          return response;
        });

        if (userResponse instanceof Error) {
          switch (userResponse.response.status) {
            case 400:
              setErrorText(userResponse.response.data);
              setChangeFail(true);
              setChangeSuccess(false);
              break;
            default:
              setSnackbar({ text: 'somethingWentWrong', severity: 'warning', open: true });
              break;
          }
        } else {
          setErrorText('');
          setChangeFail(false);
          setChangeSuccess(true);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }

  return (
    <Paper className={classes.settingsPaper} elevation={0}>
      <Typography variant='h5' gutterBottom>
        {t('changePassword')}
      </Typography>
      <div className={classes.changePasswordColumn}>
        <Typography className={classes.subHeader} gutterBottom>
          {t('changePasswordSubTitle')}
        </Typography>
        <div
          className={
            isMobile ? `${classes.passwordRow} ${classes.passwordRowMobile}` : classes.passwordRow
          }
        >
          <form className={isMobile ? classes.textField : ''}>
            <TextField
              id='old-password'
              type={'password'}
              label={t('oldPassword')}
              onChange={(event) => setOldPassword(event.target.value)}
              classes={{ root: classes.formLabel }}
              InputProps={{
                autoComplete: 'new-password',
              }}
            />
          </form>
          <form className={isMobile ? classes.textField : ''}>
            <TextField
              id='new-password'
              type={showNewPassword ? 'text' : 'password'}
              label={t('newPassword')}
              onChange={(event) => setNewPassword(event.target.value)}
              classes={{ root: classes.formLabel }}
              InputProps={{
                autoComplete: 'new-password',
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => setShowNewPassword(!showNewPassword)}
                      edge='end'
                      className={classes.iconButton}
                    >
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <Button
            className={
              isMobile
                ? `${classes.changePasswordButton} ${classes.mobileChangePasswordButton}`
                : classes.changePasswordButton
            }
            variant='contained'
            component='span'
            color='primary'
            disabled={!oldPassword || !newPassword}
            onClick={checkNewPassword}
            size='large'
            disableElevation
          >
            {t('changePassword')}
          </Button>
        </div>

        {changeFail && (
          <div className={classes.errorRow}>
            <ErrorIcon color={'error'} className={classes.errorIcon} />
            <Typography color={'error'}>{t(errorText)}</Typography>
          </div>
        )}
        {changeSuccess && (
          <div className={classes.errorRow}>
            <ErrorIcon color={'primary'} className={classes.errorIcon} />
            <Typography color={'primary'}>{t('changeSuccess')}</Typography>
          </div>
        )}
      </div>
    </Paper>
  );
}
