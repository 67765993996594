import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const FormContext = createContext({
  cowState: [],
  setCowState: () => {},
  existingCows: [],
  setExistingCows: () => {},
  selectedDate: new Date(),
  setSelectedDate: () => {},
  PPN: '',
  setPPN: () => {},
});

export const FormProvider = (props) => {
  const [cowState, setCowState] = useState([]);
  const [existingCows, setExistingCows] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [PPN, setPPN] = useState('');

  return (
    <FormContext.Provider
      value={{
        cowState,
        setCowState,
        existingCows,
        setExistingCows,
        selectedDate,
        setSelectedDate,
        PPN,
        setPPN,
      }}
    >
      {props.children}
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.object.isRequired,
};
