import React, { useCallback } from 'react';
import { makeStyles, Typography, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isPast, isFuture } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  informationCard: {
    boxSizing: 'border-box',
    boxShadow: theme.shadows.small,
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.complementColors.lightYellow,
    '& span': {
      marginRight: theme.spacing(1),
      fontSize: 'x-large',
    },
  },
  link: {
    cursor: 'pointer',
  },
}));

export default function ToDoInfoCard(props) {
  const { onClickSeeTodaysTasks, activePage, isCurrentPageEmpty } = props;
  const classes = useStyles();
  const { t } = useTranslation('to-do');

  const getTitle = useCallback(() => {
    if (isPast(activePage)) {
      return t('newDay');
    } else if (isFuture(activePage)) {
      return t('planTomorrow');
    }
  }, [activePage, t]);

  const getTextBody = useCallback(() => {
    if (isPast(activePage)) {
      return (
        <>
          <u className={classes.link} onClick={onClickSeeTodaysTasks}>
            {t('seeToday1')}
          </u>{' '}
          {t('seeToday2')}
        </>
      );
    } else if (isFuture(activePage)) {
      if (isCurrentPageEmpty) {
        return t('futureTasksInformationEmptyState');
      } else {
        return t('futureTasksInformation');
      }
    }
  }, [activePage, classes.link, isCurrentPageEmpty, onClickSeeTodaysTasks, t]);

  return (
    <Paper className={classes.informationCard} elevation={0}>
      <Typography variant='h5' gutterBottom>
        {getTitle()}
      </Typography>
      <Typography>{getTextBody()}</Typography>
    </Paper>
  );
}

ToDoInfoCard.propTypes = {
  onClickSeeTodaysTasks: PropTypes.func.isRequired,
  activePage: PropTypes.instanceOf(Date).isRequired,
  isCurrentPageEmpty: PropTypes.bool.isRequired,
};
