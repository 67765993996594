import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { makeStyles, Button, useMediaQuery, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import ResultGroup from './ResultGroup';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  resultsWrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    padding: theme.spacing(1),
    overflow: 'auto',
    boxSizing: 'border-box',
    flexDirection: 'column',
    alignItems: 'center',
  },
  resultsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    '&>div+div': {
      marginTop: theme.spacing(1.5),
    },
  },
  desktopResultsContainer: {
    width: '50%',
  },
  button: {
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1.75),
  },
  spinner: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default function Result(props) {
  const { results, isLoading } = props;
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width:1160px)');
  const { t } = useTranslation('result');
  const { setHeaderTitle } = useContext(HeaderContext);

  useEffect(() => {
    setHeaderTitle(t('resultTitle'));
    document.title = `${t('resultTitle')} | KetosKoll`;
  }, [setHeaderTitle, t]);

  return (
    <div className={classes.resultsWrapper}>
      <div
        className={
          isMobile
            ? classes.resultsContainer
            : `${classes.resultsContainer} ${classes.desktopResultsContainer}`
        }
      >
        {isLoading && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}
        {results?.cancelTreatment?.length > 0 && (
          <ResultGroup type='cancelTreatment' resultGroup={results.cancelTreatment} />
        )}
        {results?.newTest?.length > 0 && (
          <ResultGroup type='newTest' resultGroup={results.newTest} />
        )}
        {results?.treatment?.length > 0 && (
          <ResultGroup type='treatment' resultGroup={results.treatment} />
        )}
        {results?.noAction?.length > 0 && (
          <ResultGroup type='noAction' resultGroup={results.noAction} />
        )}
        {results?.lateRegistration?.length > 0 && (
          <ResultGroup type='lateRegistration' resultGroup={results.lateRegistration} />
        )}
        <Button
          size='large'
          variant='contained'
          color='primary'
          disableElevation
          startIcon={<CheckIcon />}
          className={classes.button}
          component={Link}
          to='/'
        >
          {t('seeTodaysTodo')}
        </Button>
      </div>
    </div>
  );
}

Result.propTypes = {
  results: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
