import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { HeaderContext } from '../../Contexts/HeaderContext';
import PlaylistAddCheck from '@material-ui/icons/PlaylistAddCheck';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link, Redirect } from 'react-router-dom';
import { enums } from '../../Services/Enumerations';
import InfoBox from '../InfoBox';

const useStyles = makeStyles((theme) => ({
  pickSelectionMethod: {
    boxSizing: 'border-box',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  buttonContainer: {
    display: 'flex',
    marginTop: '3rem',
    '& p': {
      textAlign: 'center',
    },
  },
  buttonAndTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: theme.spacing(9),
    height: theme.spacing(12),
  },
  strategicButtonAndTextWrapper: {
    marginRight: theme.spacing(1),
  },
  button: {
    borderRadius: '50%',
    width: '5rem',
    height: '5rem',
    marginBottom: theme.spacing(2),
    '& svg': {
      width: '2.5rem',
      height: '2.5rem',
    },
  },
  strategic: {
    backgroundColor: '#fff',
  },
  footer: {
    boxSizing: 'border-box',
    width: '100%',
    position: 'absolute',
    display: 'flex',
    bottom: 0,
    '& a': {
      margin: theme.spacing(0, 0, 1, 1),
    },
  },
  goBackButton: {
    width: theme.spacing(8),
    borderRadius: '4px',
  },
}));

export default function ReasonType(props) {
  const { testType, setReasonType } = props;
  const { t } = useTranslation('reasonTypeComponent');
  const { setHeaderTitle, setShowQuestionmark, showInfoBox } = useContext(HeaderContext);
  const classes = useStyles();

  useEffect(() => {
    setShowQuestionmark(true);
  }, [setShowQuestionmark]);

  useEffect(() => {
    switch (testType) {
      case enums.BhbMeasurementMethod.Blood:
        setHeaderTitle(t('testTypeComponent:bloodTest'));
        document.title = `${t('testTypeComponent:bloodTest')} | KetosKoll`;
        break;
      case enums.BhbMeasurementMethod.Milk:
        setHeaderTitle(t('testTypeComponent:milkTest'));
        document.title = `${t('testTypeComponent:milkTest')} | KetosKoll`;
        break;
      default:
        break;
    }
  }, [testType, setHeaderTitle, t]);

  // If the page is refreshed, the props are gone and we need to redirect back to start
  if (testType === enums.BhbMeasurementMethod.NotSet) {
    return <Redirect to='/new-test' />;
  }

  const StrategicButton = () => {
    return (
      <div className={`${classes.buttonAndTextWrapper} ${classes.strategicButtonAndTextWrapper}`}>
        <Button
          variant='contained'
          disableElevation
          component={Link}
          to='/form'
          onClick={() => {
            setReasonType(enums.MeasurementReason.StrategicSelection);
          }}
          className={`${classes.button} ${classes.strategic}`}
        >
          <PlaylistAddCheck color='primary' fontSize='large' />
        </Button>
        <Typography>{t('strategic')}</Typography>
      </div>
    );
  };

  const SymptomButton = () => {
    return (
      <div className={classes.buttonAndTextWrapper}>
        <Button
          color='primary'
          variant='contained'
          disableElevation
          component={Link}
          to='/form'
          onClick={() => {
            setReasonType(enums.MeasurementReason.Symptom);
          }}
          className={`${classes.button}`}
        >
          <VisibilityIcon fontSize='large' />
        </Button>
        <Typography>{t('symptom')}</Typography>
      </div>
    );
  };

  const GoBackButton = () => {
    return (
      <Button
        className={classes.goBackButton}
        variant='outlined'
        color='primary'
        size='large'
        component={Link}
        to={'/new-test'}
      >
        <Typography variant='h5'>{t('goBack')}</Typography>
      </Button>
    );
  };

  return (
    <>
      <div className={classes.pickSelectionMethod}>
        {showInfoBox ? <InfoBox infoType={enums.InfoType.ReasonType} /> : <> </>}
        <Typography variant='h4'>{t('titleText')}</Typography>
        <div className={classes.buttonContainer}>
          <StrategicButton />
          <SymptomButton />
        </div>
        <div className={classes.footer}>
          <GoBackButton />
        </div>
      </div>
    </>
  );
}

ReasonType.propTypes = {
  testType: PropTypes.number.isRequired,
  setReasonType: PropTypes.func.isRequired,
};
