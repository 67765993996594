import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { HeaderContext } from '../../Contexts/HeaderContext';
import { useTranslation } from 'react-i18next';
import { Typography, Button, Dialog } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { enums } from '../../Services/Enumerations';
import FormInfoContent from './FormInfoContent';
import ReasonTypeInfoContent from './ReasonTypeInfoContent';
import ResultInfoContent from './ResultInfoContent';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'absolute',
    justifyContent: 'center',
    height: 'calc(100% - 8rem)',
    width: '100vw',
    backgroundColor: 'rgba(128,128,128,0.8)',
    zIndex: '10',
    transition: 'height 400ms ease-out',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dialogHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.25),
    '&>svg': {
      marginRight: theme.spacing(0.5),
    },
  },
  dialogBody: {
    padding: theme.spacing(0, 1.5),
    overflow: 'auto',
  },
  dialogFooter: {
    padding: theme.spacing(1.5),
  },
}));

export default function InfoBox(props) {
  const infoType = props.infoType;
  const { t } = useTranslation(['infoBox', 'formInfoBox', 'reasonInfoBox', 'resultInfoBox']);
  const classes = useStyles();
  const { showInfoBox, setShowInfoBox } = useContext(HeaderContext);

  const hideQuestionDiv = () => {
    setShowInfoBox(false);
  };

  const getTitle = () => {
    switch (infoType) {
      case enums.InfoType.Form:
        return t('formInfoBox:title');
      case enums.InfoType.ReasonType:
        return t('reasonInfoBox:title');
      case enums.InfoType.ResultType:
        return t('resultInfoBox:title');
      default:
        return <></>;
    }
  };

  const getContent = () => {
    switch (infoType) {
      case enums.InfoType.Form:
        return <FormInfoContent />;
      case enums.InfoType.ReasonType:
        return <ReasonTypeInfoContent />;
      case enums.InfoType.ResultType:
        return <ResultInfoContent />;
      default:
        return <></>;
    }
  };

  const Header = () => {
    return (
      <div className={classes.dialogHeader}>
        <HelpOutlineIcon />
        <Typography variant='subtitle1'>{getTitle()}</Typography>
      </div>
    );
  };

  const Body = () => {
    return <div className={classes.dialogBody}>{getContent()}</div>;
  };

  const Footer = () => {
    return (
      <div className={classes.dialogFooter}>
        <Button
          variant='contained'
          color='primary'
          className={classes.button}
          onClick={hideQuestionDiv}
          fullWidth
          disableElevation
          size='large'
        >
          <Typography> {t('infoBox:buttonText')} </Typography>
        </Button>
      </div>
    );
  };

  return (
    <Dialog open={showInfoBox} onClose={() => setShowInfoBox(false)}>
      <Header />
      <Body />
      <Footer />
    </Dialog>
  );
}

InfoBox.propTypes = {
  infoType: PropTypes.number.isRequired,
};
