import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  infoItem: {
    marginBottom: theme.spacing(2),
  },
}));

export default function FormInfoContent() {
  const classes = useStyles();
  const { t } = useTranslation('formInfoBox');

  return (
    <>
      <div className={classes.infoItem}>
        <Typography variant='h5' gutterBottom>
          {t('dim')}
        </Typography>
        <Typography>{t('dimText')}</Typography>
      </div>
      <div className={classes.infoItem}>
        <Typography variant='h5' gutterBottom>
          {t('bhb')}
        </Typography>
        <Typography>{t('bhbText')}</Typography>
      </div>
      <div className={classes.infoItem}>
        <Typography variant='h5' gutterBottom>
          {t('symptom')}
        </Typography>
        <Typography>{t('symptomText')}</Typography>
      </div>
      <div>
        <Typography variant='h5' gutterBottom>
          {t('knownKetosis')}
        </Typography>
        <Typography>{t('knownKetosisText')}</Typography>
      </div>
    </>
  );
}
